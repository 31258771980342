import React from "react";
import {
  CustomerInfoPDFTable,
  VerticalPDFTable,
  HorizontalPDFTable,
} from "./components";
import {
  getBiriteCompanyName,
  invoiceDisplayType,
  getCompanyLogoURI,
  formatAsMoney,
} from "./helpers";
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Svg,
  Line,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    padding: "15",
    fontSize: 12,
    flexDirection: "column",
    fontFamily: "Helvetica",
  },
  logo: {
    width: "100",
  },
  row: {
    flexDirection: "row",
    display: "flex",
    flexWrap: "wrap",
    marginTop: 10,
    marginBottom: 10,
  },
  col: {
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
    flex: 1,
    minWidth: 0,
    maxWidth: "100%",
    paddingLeft: 10,
    paddingRight: 10,
  },
  paymentHeader: {
    fontSize: 18,
    paddingTop: 15,
    paddingRight: 15,
    fontFamily: "Helvetica",
  },
  paymentSubheader: {
    fontSize: 14,
    textAlign: "right",
    fontFamily: "Helvetica",
  },
  paymentSectionHeader: {
    fontSize: 10,
    marginBottom: 5,
    color: "#9CA8B7",
    fontFamily: "Helvetica",
  },
  invoiceHeader: {
    fontSize: 16,
    paddingTop: 15,
    paddingRight: 15,
  },
  invoiceSubheader: {
    paddingTop: 10,
    paddingRight: 15,
  },
  invoiceSuperfooter: {
    paddingTop: 15,
    paddingRight: 15,
  },
  invoiceFooter: {
    paddingTop: 5,
    paddingRight: 15,
  },
  // Column styles
  id: {
    maxWidth: "10%",
  },
  description: {
    flex: 4,
    flexGrow: 4,
  },
  "vendor item #": {
    flex: 2,
    flexGrow: 2,
  },
  "Category Recap Summary": {
    maxWidth: "40%",
  },
  "Due On": {
    marginBottom: 10,
  },
});

export const BiriteInvoice = ({
  account,
  invoice,
  customerInfo,
  accountInfoData,
  costSummaryData,
  itemsData,
  categoryRecapData,
}) => {
  return (
    <Document>
      <Page size="A3" orientation="landscape" style={styles.page}>
        <View style={styles.row}>
          <View style={styles.col}>
            <CustomerInfoPDFTable
              account={account}
              customer={customerInfo}
              styles={styles}
            />
          </View>
          <View style={{ ...styles.col, textAlign: "right" }}>
            <Text style={styles.invoiceHeader}>
              {invoiceDisplayType(invoice.type)} #{invoice.id}
            </Text>
            {invoice.type.toUpperCase() === "CREDIT" &&
              invoice.related_to_id !== "" && (
                <Text style={styles.invoiceSubheader}>
                  ORIG INV #{invoice.related_to_id}
                </Text>
              )}
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.col}>
            <VerticalPDFTable
              name="Account Info"
              keys={Object.keys(accountInfoData)}
              values={Object.values(accountInfoData)}
              globalStyles={styles}
            />
          </View>
          <View style={styles.col}>
            <VerticalPDFTable
              name="Invoice Summary"
              keys={Object.keys(costSummaryData)}
              values={Object.values(costSummaryData)}
              globalStyles={styles}
            />
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.col}>
            <HorizontalPDFTable
              name="Category Recap Summary"
              keys={
                categoryRecapData.length > 0
                  ? Object.keys(categoryRecapData[0])
                  : undefined
              }
              data={categoryRecapData}
              globalStyles={styles}
            />
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.col}>
            <HorizontalPDFTable
              name="Invoice Items Summary"
              keys={
                itemsData.length > 0 ? Object.keys(itemsData[0]) : undefined
              }
              data={itemsData}
              globalStyles={styles}
            />
          </View>
        </View>
        <View style={styles.row}>
          <View style={{ ...styles.col }}>
            <Text style={styles.invoiceSuperfooter}>
              Pay at www.birite.com/pay-online OR MAIL TO: BiRite Restaurant
              Supply Co., Inc P.O. Box 631871 CINCINNATI, OH 45263-1871
            </Text>
            <Text style={styles.invoiceFooter}>
              Note: Please review invoice terms to determine payment date. A
              service charge of 18% per annum will be charged on past due
              accounts. This invoice incorporates the terms of sale in the{" "}
              {getBiriteCompanyName(invoice)} Customer Application, Credit
              Agreement, and Personal Guaranty.
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export const BiRitePaymentReceipt = ({
  account,
  payment,
  paidInvoices,
  paymentTotal,
  paymentReceiptHeader,
  paymentMethod,
  paymentType,
}) => {
  return (
    <PDFViewer width="100%" height="100%">
      <Document>
        <Page style={styles.page}>
          <View style={styles.row}>
            <View style={styles.col}>
              <Image style={styles.logo} src={getCompanyLogoURI(account)} />
            </View>
            <View style={styles.col}>
              <Text
                style={{
                  ...styles.paymentSectionHeader,
                  textAlign: "right",
                  marginTop: 10,
                }}
              >
                Payment No#
              </Text>
              <Text style={styles.paymentSubheader}>{payment.hashid}</Text>
            </View>
          </View>
          <View style={{ ...styles.row, marginBottom: 20 }}>
            <View style={styles.col}>
              <Text style={styles.paymentHeader}>{paymentReceiptHeader}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.col}>
              <Text style={styles.paymentSectionHeader}>AMOUNT PAID</Text>
              <Text>{formatAsMoney(paymentTotal / 100)}</Text>
            </View>
            <View style={styles.col}>
              <Text style={styles.paymentSectionHeader}>PAYMENT DATE</Text>
              <Text>
                {(payment.scheduled || payment.created)
                  .toDate()
                  .toLocaleDateString()}
              </Text>
            </View>
            <View style={styles.col}>
              <Text style={styles.paymentSectionHeader}>PAYMENT METHOD</Text>
              <Text>{paymentMethod}</Text>
            </View>
          </View>
          <View style={{ ...styles.row, marginTop: 30, marginBottom: 0 }}>
            <View style={styles.col}>
              <Text style={styles.paymentSectionHeader}>DETAILS</Text>
            </View>
          </View>
          <View style={{ ...styles.row, marginTop: 0 }}>
            <View style={styles.col}>
              {/* Paid invoices table */}
              {paymentType.toUpperCase() === "LOAN_PAYMENT" ? (
                <View style={{ ...styles.row, marginTop: 5 }}>
                  <View style={{ ...styles.col, paddingLeft: 0 }}>
                    <Text>
                      Payment {payment.num_in_series} of {payment.series_length}{" "}
                      for:
                    </Text>
                    {paidInvoices.map((inv, i) => (
                      <View
                        key={`trow-${inv.id}`}
                        style={{ ...styles.row, paddingTop: 2 }}
                      >
                        <View
                          key={`desc-col-${inv.id}`}
                          style={{ ...styles.col, paddingLeft: 0 }}
                        >
                          <Text key={`desc-${inv.id}`}>
                            - {inv.prefix} #{inv.id}
                          </Text>
                        </View>
                      </View>
                    ))}
                  </View>
                  <View style={styles.col}>
                    <Text style={{ textAlign: "right" }}>
                      {formatAsMoney(payment.principal_amount / 100)}
                    </Text>
                  </View>
                </View>
              ) : (
                <>
                  {paidInvoices.map((inv, i) => (
                    <View
                      key={`trow-${inv.id}`}
                      style={{ ...styles.row, marginTop: 5 }}
                    >
                      <View
                        key={`desc-col-${inv.id}`}
                        style={{ ...styles.col, paddingLeft: 0 }}
                      >
                        <Text key={`desc-${inv.id}`}>
                          {inv.prefix} #{inv.id}
                        </Text>
                      </View>
                      <View key={`amt-col-${inv.id}`} style={styles.col}>
                        <Text
                          key={`amt-${inv.id}`}
                          style={{ textAlign: "right" }}
                        >
                          {formatAsMoney(inv.amount / 100)}
                        </Text>
                      </View>
                    </View>
                  ))}
                </>
              )}

              {/** Surcharge Area**/}
              {payment.method_type.toUpperCase() === "CARD" && (
                <View style={{ ...styles.row, marginTop: 5 }}>
                  <View style={{ ...styles.col, paddingLeft: 0 }}>
                    <Text>Card Surcharge</Text>
                  </View>
                  <View style={styles.col}>
                    <Text style={{ textAlign: "right" }}>
                      {formatAsMoney(payment.surcharge / 100)}
                    </Text>
                  </View>
                </View>
              )}
              {paymentType.toUpperCase() === "LOAN_PAYMENT" && (
                <View style={{ ...styles.row, marginTop: 5 }}>
                  <View style={{ ...styles.col, paddingLeft: 0 }}>
                    <Text>Fee</Text>
                  </View>
                  <View style={styles.col}>
                    <Text style={{ textAlign: "right" }}>
                      {formatAsMoney(payment.interest_amount / 100)}
                    </Text>
                  </View>
                </View>
              )}

              {/** Total **/}
              <View style={styles.row}>
                <View style={{ ...styles.col, paddingLeft: 0 }}>
                  <Svg height="5">
                    <Line
                      x1="0"
                      y1="0"
                      x2="1000"
                      y2="0"
                      strokeWidth={2}
                      stroke="#F9F9F9"
                    />
                  </Svg>
                </View>
              </View>
              <View style={styles.row}>
                <View
                  style={{ ...styles.col, paddingLeft: 0, fontWeight: "bold" }}
                >
                  <Text>TOTAL</Text>
                </View>
                <View style={styles.col}>
                  <Text style={{ textAlign: "right" }}>
                    {formatAsMoney(paymentTotal / 100)}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};
