import React, { Suspense } from "react";
import "./scss/style.scss";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import routes from "./routes";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const App = () => {
  return (
    <Router>
      <Suspense fallback={loading}>
        <Switch>
          {routes.map((route, idx) => {
            return (
              route.component && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  component={route.component}
                />
              )
            );
          })}
        </Switch>
      </Suspense>
    </Router>
  );
};

export default App;
