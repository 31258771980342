import React from "react";

const Home = React.lazy(() => import("./home"));
const Terms = React.lazy(() => import("./home/terms"));
const Privacy = React.lazy(() => import("./home/privacy"));

const DashboardSignIn = React.lazy(() => import("./assistant/DashboardSignIn"));
const DashboardLinkSent = React.lazy(() =>
  import("./components/SignInLinkSent")
);
const DashboardAuth = React.lazy(() => import("./assistant/DashboardAuth"));
const SalesRepProfile = React.lazy(() => import("./assistant/profile"));
const Dashboard = React.lazy(() => import("./assistant/dashboard"));
const GoogleAuthHandler = React.lazy(() => import("./assistant/GAuth"));

const Assistant = React.lazy(() => import("./Assistant_v2"));
const AssistantSignIn = React.lazy(() => import("./Assistant_v2/SignIn"));
const AssistantLinkSent = React.lazy(() =>
  import("./components/SignInLinkSent")
);
const AssistantAuth = React.lazy(() =>
  import("./Assistant_v2/SignIn/AssistantAuth")
);
const SalesRepOnboarding = React.lazy(() =>
  import("./Assistant_v2/Onboarding")
);

const Pay = React.lazy(() => import("./Pay"));
const ClientNotFound = React.lazy(() => import("./Pay/PayerEmptyState"));
const ClientSignIn = React.lazy(() => import("./Pay/SignIn/ClientSignIn"));
const ClientAccountInvoiceSignIn = React.lazy(() =>
  import("./Pay/SignIn/AccountInvoiceSignIn")
);
const ClientLinkSent = React.lazy(() => import("./components/SignInLinkSent"));
const ClientAuth = React.lazy(() => import("./Pay/SignIn/ClientAuth"));
const ClientRecipientAdded = React.lazy(() =>
  import("./Pay/PayRecipientAdded")
);
const ViewInvoice = React.lazy(() => import("./Pay/ViewInvoice"));
const ViewPaymentReceipt = React.lazy(() =>
  import("./components/ViewPaymentReceipt")
);

const Cushion = React.lazy(() => import("./Cushion"));
const CushionSignIn = React.lazy(() =>
  import("./Cushion/SignIn/CushionSignIn")
);
const CushionRegisterEmail = React.lazy(() =>
  import("./Cushion/SignIn/CushionRegisterEmail")
);
const CushionLinkSent = React.lazy(() => import("./components/SignInLinkSent"));
const CushionAuth = React.lazy(() => import("./Cushion/SignIn/CushionAuth"));
const CushionFundboxApplication = React.lazy(() =>
  import("./Cushion/FundboxApplication")
);

const Page404 = React.lazy(() => import("./home/404"));

const routes = [
  { path: "/", exact: true, component: Home },
  { path: "/terms", name: "Terms", component: Terms, exact: true },
  { path: "/privacy", name: "Privacy", component: Privacy, exact: true },
  {
    path: "/dashboard/sign-in",
    name: "Assistant Sign In",
    component: DashboardSignIn,
    exact: true,
  },
  {
    path: "/dashboard/sign-in-link-sent",
    name: "Assistant Sign In Link Sent",
    component: DashboardLinkSent,
    exact: true,
  },
  {
    path: "/dashboard/auth",
    name: "Assistant Auth",
    component: DashboardAuth,
    exact: true,
  },
  {
    path: "/dashboard/profile",
    name: "Sales Profile",
    component: SalesRepProfile,
    exact: true,
  },
  {
    path: "/dashboard",
    name: "Assistant Dashboard",
    component: Dashboard,
    exact: true,
  },
  {
    path: "/gauth",
    name: "Google Auth",
    component: GoogleAuthHandler,
    exact: true,
  },
  {
    path: "/assistant/sign-in",
    name: "Assistant Sign In",
    component: AssistantSignIn,
    exact: true,
  },
  {
    path: "/assistant/sign-in-link-sent",
    name: "Assistant Sign In Link Sent",
    component: AssistantLinkSent,
    exact: true,
  },
  {
    path: "/assistant/sales-rep-onboarding",
    name: "Assistant Sales Rep Onboarding",
    component: SalesRepOnboarding,
    exact: true,
  },
  {
    path: "/assistant/auth",
    name: "Assistant Auth",
    component: AssistantAuth,
    exact: true,
  },
  {
    path: "/assistant",
    name: "Assistant | Anansii",
    component: Assistant,
  },
  {
    path: "/pay/sign-in",
    name: "Client Sign In",
    component: ClientSignIn,
    exact: true,
  },
  {
    path: "/pay/sign-in/enter-account-invoice-num",
    name: "Client Account & Invoice Sign In",
    component: ClientAccountInvoiceSignIn,
    exact: true,
  },
  {
    path: "/pay/sign-in-link-sent",
    name: "Client Sign In Link Sent",
    component: ClientLinkSent,
    exact: true,
  },
  {
    path: "/pay/auth",
    name: "Client Auth",
    component: ClientAuth,
    exact: true,
  },
  {
    path: "/pay/client-not-found",
    name: "Client not recognized",
    component: ClientNotFound,
    exact: true,
  },
  {
    path: "/pay/notification-recipient-added",
    name: "Recipient Added",
    component: ClientRecipientAdded,
    exact: true,
  },
  {
    path: "/view-invoice",
    name: "View Invoice PDF",
    component: ViewInvoice,
  },
  {
    path: "/view-payment-receipt",
    name: "View Payment Receipt PDF",
    component: ViewPaymentReceipt,
  },
  { path: "/clients", name: "Customer Portal", component: Pay },
  { path: "/pay", name: "Customer Portal", component: Pay },
  {
    path: "/cushion/fundbox-application",
    name: "Cushion - Fundbox Application",
    component: Cushion,
    exact: true,
  },
  {
    path: "/cushion/sign-in-link-sent",
    name: "Cushion - Sign In Link Sent",
    component: CushionLinkSent,
    exact: true,
  },
  {
    path: "/cushion/auth",
    name: "Cushion - User Auth",
    component: CushionAuth,
    exact: true,
  },
  {
    path: "/cushion/sign-in",
    name: "Cushion - Sign In",
    component: CushionSignIn,
    exact: true,
  },
  {
    path: "/cushion/register-email",
    name: "Cushion - Register Email",
    component: CushionRegisterEmail,
    exact: true,
  },
  { path: "/cushion", name: "Cushion", component: Cushion },
  { path: "*", name: "404!", component: Page404 },
];

export default routes;
