import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
  forwardRef,
} from "react";
import {
  CBadge,
  CRow,
  CCol,
  CContainer,
  CButton,
  CTextarea,
  CLabel,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CInputCheckbox,
  CFormGroup,
  CDataTable,
  CInputRadio,
  CInput,
  CCollapse,
  CCard,
  CCardBody,
  CTooltip,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { freeSet } from "@coreui/icons";
import { brandSet } from "@coreui/icons";
import { StyleSheet, Text, View, Image, Font } from "@react-pdf/renderer";
import {
  badgeColor,
  Keys,
  getCompanyLogoURI,
  formatAsMoney,
  creditsBalanceText,
  encodeForURI,
  isLinkableInvoice,
  formatTimestampAsDate,
  invoiceStatusBadgeColor,
  isLocal,
  cardIcons,
  invoiceStatusFromDueDate,
  isValidAccountNumber,
  invoiceDisplayType,
  INSTALLMENT_OFFERS,
  isPastDueInvoice,
  formatAsPaidInvoiceString,
  formatScheduledPaymentsString,
  timestampAsLuxonDateTime,
  isPaymentForPastDueInvoice,
} from "./helpers";
import { PlaidLink } from "./paycomponents";
import Strings from "./strings";

import { FirebaseContext } from "../firebase";
import { toast } from "react-toastify";

import { useCombobox } from "downshift";
import { NumericFormat } from "react-number-format";

import { DateTime } from "luxon";

// TODO: pass in customer name to this component
// and simply see if there is a doc at /customers/${customerName}/accounts/${accountNumber}/invoices/${invoiceNumber}
export const AccountAndInvoiceAuthForm = ({
  cta,
  onSuccess,
  isLoading,
  handleLoadingChange,
}) => {
  const firebase = useContext(FirebaseContext);

  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [accountNumber, setAccountNumber] = useState("");

  const [payerInvoices, setPayerInvoices] = useState(null);
  const [accountInvoices, setAccountInvoices] = useState(null);
  useEffect(() => {
    if (!payerInvoices || !accountInvoices) return;
    const combined = [...payerInvoices, ...accountInvoices];
    const inv = combined.find((el) => el.id === invoiceNumber);

    if (!inv) {
      // console.log(
      //   "no invoices found for that account number and invoice number"
      // );
      toast.error(
        "Account not found! Please verify the account number and invoice number, and try again."
      );
      handleLoadingChange(false);
      return;
    }

    onSuccess(inv, accountNumber);
  }, [payerInvoices, accountInvoices]);
  const handleFormSubmitted = async () => {
    handleLoadingChange(true);
    firebase.getInvoicesByPayerIdOnce(accountNumber, setPayerInvoices);
    firebase.getInvoicesByAccountIdOnce(accountNumber, setAccountInvoices);
  };

  return (
    <>
      <CFormGroup row>
        <CCol>
          <CLabel
            htmlFor="accountNumber"
            className="text-muted font-weight-bold"
          >
            Enter your account number
          </CLabel>
          <CInput
            id="accountNumber"
            name="accountNumber"
            placeholder="-------"
            maxLength="8"
            value={accountNumber}
            onChange={(e) => setAccountNumber(e.target.value)}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol>
          <CLabel
            htmlFor="invoiceNumber"
            className="text-muted font-weight-bold"
          >
            Enter an invoice number
          </CLabel>
          <CInput
            id="invoiceNumber"
            name="invoiceNumber"
            placeholder="--------"
            maxLength="7"
            value={invoiceNumber}
            onChange={(e) => setInvoiceNumber(e.target.value)}
          />
        </CCol>
      </CFormGroup>
      <CRow>
        <CCol>
          <SubmitBtn
            cta={cta}
            callback={handleFormSubmitted}
            isLoading={isLoading}
          />
        </CCol>
      </CRow>
    </>
  );
};

export const CalendarInput = forwardRef(({ value, onClick }, ref) => (
  <CRow className="text-center font-weight-bold pt-1">
    <CCol className="align-baseline">
      <CLabel className="m-0">Pay on&nbsp;</CLabel>
      <span className="text-nowrap">
        <CIcon content={freeSet.cilCalendar} className="mb-1 mx-1" />
        <label onClick={onClick} ref={ref} className="m-0 p-0">
          {value}
        </label>
      </span>
      &nbsp; [
      <CButton
        color="link"
        className="text-primary font-weight-bold border-0 align-baseline p-0"
        onClick={onClick}
      >
        Change
      </CButton>
      ]
    </CCol>
  </CRow>
));

export const ScheduledPaymentsToggle = ({ isOn, handleToggleClick }) => {
  const classes = "text-white font-weight-bold w-100 text-right";
  const variant = "ghost";
  const size = "sm";
  const color = "info";
  const shape = "square";

  if (isOn) {
    return (
      <CButton
        className={classes}
        variant={variant}
        size={size}
        color={color}
        shape={shape}
        onClick={() => handleToggleClick(false)}
      >
        Hide ▲
      </CButton>
    );
  }

  return (
    <CButton
      className={classes}
      variant={variant}
      size={size}
      color={color}
      shape={shape}
      onClick={() => handleToggleClick(true)}
    >
      Show details ▼
    </CButton>
  );
};

const ScheduledPaymentCancelButton = ({
  p,
  openInvoices,
  isAdminUser,
  handleClick,
}) => {
  // no one can cancel a scheduled loan payment
  if (p.payment_type === "loan_payment")
    return (
      <CTooltip content="Upcoming pay-over-time payments cannot be cancelled">
        <span className="d-inline-block" tabIndex={0}>
          <CButton
            key={`scheduled-loan-payment-cancel-${p.id}`}
            color="link"
            className="p-0 border-0 mr-2"
            aria-label={`cancel-loan-scheduled-payment-${p.id}`}
            disabled
          >
            <CIcon
              key={`scheduled-loan-payment-cancel-icon-${p.id}`}
              content={freeSet.cilXCircle}
              className="text-white text-center"
            />
          </CButton>
        </span>
      </CTooltip>
    );

  // only admins can cancel a scheduled payment where a paid invoice has a due date in the past
  if (!isAdminUser && isPaymentForPastDueInvoice(p, openInvoices))
    return (
      <CTooltip content="Upcoming payments for past due invoices cannot be cancelled">
        <span className="d-inline-block" tabIndex={0}>
          <CButton
            key={`scheduled-payment-cancel-${p.id}`}
            color="link"
            className="p-0 border-0 mr-2"
            aria-label={`cancel-scheduled-payment-${p.id}`}
            disabled
          >
            <CIcon
              key={`scheduled-payment-cancel-icon-${p.id}`}
              content={freeSet.cilXCircle}
              className="text-white text-center"
            />
          </CButton>
        </span>
      </CTooltip>
    );

  return (
    <CButton
      key={`scheduled-payment-cancel-${p.id}`}
      color="link"
      className="p-0 border-0 mr-2"
      onClick={() => handleClick(p)}
      aria-label={`cancel-scheduled-payment-${p.id}`}
    >
      <CIcon
        key={`scheduled-payment-cancel-icon-${p.id}`}
        content={freeSet.cilXCircle}
        className="text-white text-center"
      />
    </CButton>
  );
};

export const ScheduledPaymentsList = ({
  isVisible,
  openInvoices,
  scheduledPayments,
  isAdminUser,
  handleScheduledPaymentCancelClick,
}) => {
  return (
    <CRow>
      <CCol>
        <CCollapse show={isVisible}>
          <ul className="m-0 p-0" aria-label="scheduled-payments-list">
            {scheduledPayments.map((p) => (
              <li
                aria-label={`scheduled-payment-${p.id}`}
                key={`scheduled-payment-${p.id}`}
                className="align-items-center d-flex"
              >
                <ScheduledPaymentCancelButton
                  p={p}
                  openInvoices={openInvoices}
                  isAdminUser={isAdminUser}
                  handleClick={handleScheduledPaymentCancelClick}
                />
                {formatScheduledPaymentsString(p)}
              </li>
            ))}
          </ul>
        </CCollapse>
      </CCol>
    </CRow>
  );
};

export const ScheduledPaymentsView = ({
  account,
  openInvoices,
  firebase,
  scheduledPayments,
  doubleConfirm = true,
  isAdminUser = false,
}) => {
  const [showDetail, setShowDetail] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  useEffect(() => {
    if (!selectedPayment) return;

    if (doubleConfirm) setShowConfirmation(true);
    else {
      cancelScheduledPayment();
    }
  }, [selectedPayment]);

  const cancelScheduledPayment = async () => {
    try {
      firebase.updateCancelledPaymentStatus(selectedPayment.path);
      toast.success("Scheduled payment cancelled");
    } catch (err) {
      console.error("something went wrong cancelling scheduled payment", err);
      toast.error("Something went wrong! Please try again later");
    } finally {
      setSelectedPayment(null);
    }
  };

  const [sortedScheduledPayments, setSortedScheduledPayments] = useState(null);
  useEffect(() => {
    if (!scheduledPayments) {
      setSortedScheduledPayments(null);
      return;
    }

    const copy = scheduledPayments.slice();
    const sorted = copy.sort(
      (a, b) => a.scheduled.valueOf() - b.scheduled.valueOf()
    );

    setSortedScheduledPayments(sorted);
  }, [scheduledPayments]);

  if (!sortedScheduledPayments || sortedScheduledPayments.length === 0)
    return null;

  return (
    <>
      <CCard>
        <CCardBody className="font-weight-bold bg-info text-white mx-n1 rounded-lg shadow py-3">
          <CRow className="align-items-center">
            <CCol xs="auto">
              <span>
                You have {sortedScheduledPayments.length} upcoming{" "}
                {sortedScheduledPayments.length > 1 ? "payments" : "payment"}
              </span>
            </CCol>
            <CCol className="text-white">
              <hr className="border-white" style={{ opacity: 0.4 }} />
            </CCol>
            <CCol xs="auto" className="text-right">
              <ScheduledPaymentsToggle
                isOn={showDetail}
                handleToggleClick={setShowDetail}
              />
            </CCol>
          </CRow>
          <ScheduledPaymentsList
            isVisible={showDetail}
            openInvoices={openInvoices}
            scheduledPayments={sortedScheduledPayments}
            isAdminUser={isAdminUser}
            handleScheduledPaymentCancelClick={setSelectedPayment}
          />
        </CCardBody>
      </CCard>
      {selectedPayment && showConfirmation && (
        <ConfirmationDialog
          name="cancel-scheduled-payment-dialog"
          isOpen={true}
          header="Cancel Upcoming Payment?"
          body={`Are you sure that you'd like to cancel this upcoming payment: ${formatScheduledPaymentsString(
            selectedPayment,
            false
          )}?`}
          primaryCtaText="Confirm"
          handleConfirm={cancelScheduledPayment}
          handleClose={() => setSelectedPayment(null)}
        />
      )}
    </>
  );
};

export const ConfirmationDialog = ({
  name,
  isOpen,
  isLoading = false,
  header,
  body,
  primaryCtaText,
  primaryCtaColor = "primary",
  secondaryCtaText = "Cancel",
  handleConfirm,
  handleClose,
}) => {
  if (!isOpen) return null;

  return (
    <CModal
      centered={true}
      show={isOpen}
      onClose={handleClose}
      data-testid={name}
    >
      <CModalHeader
        className="border-0 mx-3 mt-3 bg-white text-body"
        closeButton
      >
        <h5 className="font-weight-bold mb-0">{header}</h5>
      </CModalHeader>
      <CModalBody className="pt-0 mx-3 my-3">
        <CRow>
          <CCol>{body}</CCol>
        </CRow>
      </CModalBody>
      <CModalFooter className="mb-3">
        <CRow>
          <CCol className="pr-0">
            <CButton
              data-testid={`${name}-cancel-btn`}
              color="link"
              onClick={handleClose}
            >
              {secondaryCtaText}
            </CButton>
          </CCol>
          <CCol xs="auto">
            {
              {
                false: (
                  <CButton
                    data-testid={`${name}-confirm-btn`}
                    color={primaryCtaColor}
                    shape="pill"
                    className="font-weight-bold"
                    onClick={handleConfirm}
                  >
                    {primaryCtaText}
                  </CButton>
                ),
                true: (
                  <CButton
                    data-testid={`${name}-confirm-btn-loading`}
                    color={primaryCtaColor}
                    shape="pill"
                    className="font-weight-bold"
                    onClick={handleConfirm}
                  >
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </CButton>
                ),
              }[isLoading]
            }
          </CCol>
        </CRow>
      </CModalFooter>
    </CModal>
  );
};

export const InvoiceSelection = ({
  account,
  openInvoices,
  handleSelectionChange,
}) => {
  const [isCombinedStatementPayer, setIsCombinedStatementPayer] = useState(
    null
  );
  useEffect(() => {
    if (!openInvoices && isCombinedStatementPayer === null) return;
    setIsCombinedStatementPayer(
      !openInvoices.every((invoice) => invoice.account_id === account.id)
    );
  }, [openInvoices]);

  const [filter, setFilter] = useState(null);

  const [invoiceFields, setInvoiceFields] = useState([]);
  useEffect(() => {
    let updated;
    isCombinedStatementPayer
      ? (updated = [
          { key: "id", label: "Invoice ID", _style: { border: 0 } },
          { key: "account_name", _style: { border: 0 } },
          { key: "date", label: "Due Date", _style: { border: 0 } },
          { key: "status", sorter: false, _style: { border: 0 } },
          { key: "total", _style: { border: 0 } },
        ])
      : (updated = [
          { key: "id" },
          { key: "date" },
          { key: "status" },
          { key: "total" },
        ]);

    setInvoiceFields(updated);
  }, [isCombinedStatementPayer]);

  const handleCheckboxToggle = (index) => {
    const updated = openInvoices.slice();
    updated[index]["isSelected"] = !openInvoices[index]["isSelected"];
    updated[index]["paymentAmount"] = updated[index]["isSelected"]
      ? openInvoices[index]["balance"]
      : 0;
    handleSelectionChange(updated);
  };

  const selectAllInvoices = () => {
    const updated = [];
    for (let inv of openInvoices) {
      if (inv.status.toUpperCase() === "PENDING") updated.push(inv);
      else if (filter && !filter.includes(inv.account_id)) {
        updated.push(inv);
      } else {
        updated.push({
          ...inv,
          isSelected: true,
          paymentAmount: inv.balance,
        });
      }
    }
    handleSelectionChange(updated);
  };

  const deselectAllInvoices = () => {
    const updated = [];
    for (let inv of openInvoices) {
      if (inv.status.toUpperCase() === "PENDING") updated.push(inv);
      else {
        updated.push({
          ...inv,
          isSelected: false,
          paymentAmount: 0,
        });
      }
    }
    handleSelectionChange(updated);
  };

  const selectOnlyPastDueInvoices = () => {
    const updated = [];
    for (let inv of openInvoices) {
      if (inv.status.toUpperCase() === "PENDING") updated.push(inv);
      else if (filter && !filter.includes(inv.account_id)) {
        updated.push(inv);
      } else if (invoiceStatusFromDueDate(inv) === "past due") {
        updated.push({
          ...inv,
          isSelected: true,
          paymentAmount: inv.balance,
        });
      } else {
        updated.push({
          ...inv,
          isSelected: false,
          paymentAmount: 0,
        });
      }
    }
    handleSelectionChange(updated);
  };

  const prettifyPaymentAmountValue = (value, balance) => {
    if (value === balance) return (value / 100).toFixed(2);
    return value / 100;
  };

  const cleanPaymentAmount = (value, item) => {
    if (value === undefined) return null;

    if (item.balance < 0 && value > 0) return value * -100;

    return value * 100;
  };
  const changePaymentAmount = (values, item) => {
    const updated = sortedOpenInvoices.slice();
    updated[item.index]["paymentAmount"] = cleanPaymentAmount(
      values.floatValue,
      item
    );
    handleSelectionChange(updated);
  };

  const [sortedOpenInvoices, setSortedOpenInvoices] = useState([]);
  useEffect(() => {
    if (!openInvoices || isCombinedStatementPayer === null) return;

    const sorted = openInvoices.slice();
    let enriched = sorted.map((invoice, i) => ({
      ...invoice,
      index: i,
      date: invoice.due_date ? invoice.due_date.toDate().valueOf() : 0,
    }));

    if (filter) {
      enriched = enriched.filter((inv) => filter.includes(inv.account_id));
    }

    setSortedOpenInvoices(enriched);
  }, [openInvoices, isCombinedStatementPayer, filter]);

  const [combinedStatementAccounts, setCombinedStatementAccounts] = useState(
    null
  );
  useEffect(() => {
    if (!openInvoices || !isCombinedStatementPayer) return;
    const accounts = openInvoices.map(
      (inv) => `${inv.account_id} ${inv.account_name}`
    );
    const deduped = [...new Set(accounts)];
    setCombinedStatementAccounts(deduped);
  }, [isCombinedStatementPayer, openInvoices]);

  const [inColumns, setInColumns] = useState(null);
  useEffect(() => {
    if (!combinedStatementAccounts) return;

    const numColumns = 3;
    const copy = combinedStatementAccounts.slice();
    const columns = Array.from(
      { length: Math.ceil(copy.length / numColumns) },
      (_, i) => copy.splice(0, numColumns)
    );
    //console.log(columns);

    const remainder =
      columns.length > 0 ? columns[columns.length - 1].length % 3 : 0;
    if (remainder !== 0) {
      let filler = null;
      for (let i = 0; i < 3 - remainder; i++) {
        columns[columns.length - 1].push(filler);
      }
    }
    setInColumns(columns);
  }, [combinedStatementAccounts]);

  const getRowClasses = (inv) => {
    if (inv.status.toUpperCase() === "PENDING") return "font-italic";

    return "";
  };

  const isLessThanBalance = (value, balance) => {
    return Math.abs(value) <= Math.abs(balance) / 100;
  };

  const CombinedAccountsList = useCallback(() => {
    if (!inColumns) return null;

    return (
      <>
        <CRow className="mb-2">
          <CCol key="account-col-all">
            {filter ? (
              <CButton
                color="link"
                onClick={() => setFilter(null)}
                className="p-0 align-baseline"
                key="account-filter-all"
              >
                All accounts
              </CButton>
            ) : (
              <b>› All accounts</b>
            )}
            {inColumns.map((col) => (
              <>
                <CRow>
                  {col.map((a) =>
                    a ? (
                      <CCol key={`combined-acct-col-${a}`}>
                        {a === filter ? (
                          <b>› {a}</b>
                        ) : (
                          <CButton
                            color="link"
                            onClick={() => setFilter(a)}
                            className="p-0 align-baseline"
                            key={`account-filter-${a}`}
                          >
                            {a}
                          </CButton>
                        )}
                      </CCol>
                    ) : (
                      <CCol></CCol>
                    )
                  )}
                </CRow>
              </>
            ))}
          </CCol>
        </CRow>
      </>
    );
  }, [inColumns, filter]);

  if (!sortedOpenInvoices) return null;

  return (
    <>
      {sortedOpenInvoices.length ? (
        <>
          <CRow className="mt-2">
            <CCol>
              <CDataTable
                items={sortedOpenInvoices}
                fields={invoiceFields}
                header={isCombinedStatementPayer}
                sorter={isCombinedStatementPayer}
                striped={true}
                outlined={true}
                itemsPerPage={10}
                sorterValue={{ column: "date", asc: true }}
                pagination
                overTableSlot={
                  <>
                    <CombinedAccountsList />
                    <CRow>
                      <CCol>
                        <CButton
                          color="link"
                          onClick={() => selectAllInvoices()}
                          className="p-0"
                        >
                          Select all
                        </CButton>
                        <span className="px-1 text-muted">|</span>
                        <CButton
                          color="link"
                          onClick={() => selectOnlyPastDueInvoices()}
                          className="p-0"
                        >
                          past due
                        </CButton>
                        <span className="px-1 text-muted">|</span>
                        <CButton
                          color="link"
                          onClick={() => deselectAllInvoices()}
                          className="p-0"
                        >
                          none
                        </CButton>
                      </CCol>
                    </CRow>
                  </>
                }
                scopedSlots={{
                  id: (item, index) => (
                    <td className={getRowClasses(item)}>
                      <CFormGroup variant="checkbox" className="mx-0" row>
                        {
                          {
                            OPEN: (
                              <CInputCheckbox
                                id={`invoice-check-${item.id}`}
                                name={`invoice-check-${item.id}`}
                                key={`invoice-check-${item.id}`}
                                checked={item.isSelected}
                                onChange={(e) =>
                                  handleCheckboxToggle(item.index)
                                }
                              />
                            ),
                            PENDING: (
                              <CIcon
                                content={freeSet.cilSync}
                                size="lg"
                                className="ml-n4 px-1 mr-1"
                              />
                            ),
                          }[item.status.toUpperCase()]
                        }
                        <CLabel
                          htmlFor={`invoice-check-${item.id}`}
                          className="mx-1"
                          variant="checkbox"
                          key={`invoice-label-${item.id}`}
                        >
                          {
                            {
                              true: (
                                <a
                                  href={`/view-invoice/${encodeForURI(
                                    item.path
                                  )}`}
                                  target="_blank"
                                  key={`invoice-link-${item.id}`}
                                  className="font-weight-bold"
                                >
                                  {invoiceDisplayType(item.type)} #{item.id}
                                </a>
                              ),
                              false: (
                                <b>
                                  {invoiceDisplayType(item.type)} #{item.id}
                                </b>
                              ),
                            }[isLinkableInvoice(item)]
                          }
                        </CLabel>
                      </CFormGroup>
                    </td>
                  ),
                  date: (item) => (
                    <td className={getRowClasses(item)}>
                      {
                        {
                          true: (
                            <span>
                              {formatTimestampAsDate(item.due_date, {
                                dateStyle: "medium",
                              })}
                            </span>
                          ),
                          false: (
                            <span>
                              {item.due_date && <>Due:&nbsp;</>}
                              {formatTimestampAsDate(item.due_date, {
                                dateStyle: "medium",
                              })}
                            </span>
                          ),
                        }[isCombinedStatementPayer]
                      }
                    </td>
                  ),
                  status: (item) => (
                    <td>
                      <CBadge
                        color={invoiceStatusBadgeColor(item.displayStatus)}
                      >
                        {item.displayStatus.toUpperCase()}
                      </CBadge>
                    </td>
                  ),
                  total: (item) => (
                    <td className={`w-25 ${getRowClasses(item)}`}>
                      {
                        {
                          true: (
                            <>
                              <NumericFormat
                                aria-label={`invoice-amount-textbox-${item.id}`}
                                placeholder="$0"
                                prefix={"$"}
                                className="form-control text-body"
                                decimalScale={2}
                                valueIsNumericString={true}
                                allowNegative={item.total < 0}
                                isAllowed={(values) => {
                                  return values.floatValue === undefined
                                    ? true
                                    : isLessThanBalance(
                                        values.floatValue,
                                        item.balance
                                      );
                                }}
                                value={item.paymentAmount / 100}
                                onValueChange={(values) =>
                                  changePaymentAmount(values, item)
                                }
                              />
                              {Math.abs(Math.round(item.paymentAmount)) <
                                Math.abs(item.balance) && (
                                <p className="text-muted font-weight-bold small mb-n2 ml-2 pt-1">
                                  of {formatAsMoney(item.balance / 100)}
                                </p>
                              )}
                            </>
                          ),
                          false: (
                            <span>{formatAsMoney(item.balance / 100)}</span>
                          ),
                        }[item.isSelected]
                      }
                    </td>
                  ),
                }}
              />
            </CCol>
          </CRow>
        </>
      ) : (
        <CRow className="mb-2 text-center mx-n2">
          <CCol>
            <InlineNotification
              message="No outstanding invoices"
              type="muted"
            />
          </CCol>
        </CRow>
      )}
    </>
  );
};

export const InstallmentsBanner = ({
  isEligible,
  paymentAccount,
  paymentAmount,
  selectedInvoices,
  entryPaymentMethodType,
  installmentsOptionCallback,
}) => {
  const firebase = useContext(FirebaseContext);

  const [msg, setMsg] = useState(null);
  useEffect(() => {
    if (!entryPaymentMethodType) {
      setMsg(null);
      return;
    }

    let m;
    switch (entryPaymentMethodType) {
      case "bank":
        m = `Need more time? Split into 3 weekly payments (only ${
          INSTALLMENT_OFFERS[0].feePct * 100
        }% fee).`;
        break;
      case "card":
        m = `Need more time? Split into 3 payments for a smaller fee (only ${
          INSTALLMENT_OFFERS[0].feePct * 100
        }%).`;
        break;
      default:
        m = null;
    }

    setMsg(m);
  }, [entryPaymentMethodType]);

  const [show, setShow] = useState(false);

  const accountHasInstallmentsEligibleInvoices = (invs) => {
    if (!invs || invs.length === 0) return false;

    const daysBeforeDueDate = 1;
    const dt = DateTime.now().setZone("America/Los_Angeles");
    const eligibilityDate = dt.plus({ days: daysBeforeDueDate }).startOf("day");

    return invs.some((inv) => {
      if (!inv || !inv.due_date) return false;
      return (
        eligibilityDate >
          timestampAsLuxonDateTime(inv.due_date).startOf("day") ||
        eligibilityDate.equals(
          timestampAsLuxonDateTime(inv.due_date).startOf("day")
        )
      );
    });
  };

  useEffect(() => {
    if (
      entryPaymentMethodType !== "card" &&
      !accountHasInstallmentsEligibleInvoices(selectedInvoices)
    )
      setShow(false);
    else if (!isEligible || !msg || paymentAmount === 0) setShow(false);
    else {
      setShow(true);
    }
  }, [
    isEligible,
    msg,
    paymentAmount,
    selectedInvoices,
    entryPaymentMethodType,
  ]);

  useEffect(() => {
    if (!show) return;
    firebase.trackEvent(
      "event",
      "installments_banner_impression",
      "birite",
      paymentAccount.id,
      {}
    );
  }, [show]);

  return (
    <CRow className="mt-n2 mb-3" style={{ opacity: 0.9 }}>
      <CCol>
        {" "}
        <CCollapse show={show}>
          <CRow className="font-weight-bold bg-info text-white mx-n2 rounded-lg shadow-sm align-items-center p-2 justify-content-center">
            <CCol xs="auto">
              <span>{msg}</span>
            </CCol>
            <CCol xs="auto">
              <CButton
                color="primary"
                shape="pill"
                variant="outline"
                className="font-weight-bold text-nowrap shadow text-info border-white bg-white"
                disabled={!show}
                onClick={() =>
                  installmentsOptionCallback({ id: "installments" })
                }
              >
                Choose payment plan
              </CButton>
            </CCol>
          </CRow>
        </CCollapse>
      </CCol>
    </CRow>
  );
};

export const MethodSelection = ({
  paymentAccount,
  contactEmail,
  methods,
  usesPlaidLink,
  handleSelection,
  showCardOptions = true,
  showHeader = true,
}) => {
  const firebase = useContext(FirebaseContext);

  const [options, setOptions] = useState(null);
  useEffect(() => {
    let updated;
    if (!methods) updated = [];
    else {
      updated = methods.map((method) => {
        switch (method.type) {
          case "bank":
            return {
              ...method,
              icon: freeSet.cilBank,
              description: `···· ${method.institution_last4 || ""}`,
            };
          case "card":
            return {
              ...method,
              icon: method.brand
                ? cardIcons[method.brand.toUpperCase()]
                : freeSet.cilCreditCard,
              description: `···· ${method.last4}`,
            };
          default:
            console.error("unknown method type");
            return null;
        }
      });
    }

    setOptions(updated);
  }, [methods]);

  const [allowPayMethodEditing, setAllowPayMethodEditing] = useState(false);
  useEffect(() => {
    setAllowPayMethodEditing(showHeader);
  }, [showHeader]);
  const [editing, setEditing] = useState(false);
  useEffect(() => {
    if (!methods || methods.length === 0) setEditing(false);
  }, [methods]);

  const [archiveSelection, setArchiveSelection] = useState(null);
  const [isArchivingSelection, setIsArchivingSelection] = useState(false);
  const [archivePaymentMethodMsg, setArchivePaymentMethodMsg] = useState("");
  useEffect(() => {
    if (!archiveSelection) {
      setArchivePaymentMethodMsg("");
      return;
    }

    archiveSelection.path === paymentAccount.autopayMethodPath
      ? setArchivePaymentMethodMsg(Strings.ARCHIVE_AUTOPAYMENT_METHOD)
      : setArchivePaymentMethodMsg(Strings.ARCHIVE_PAYMENT_METHOD);
  }, [archiveSelection]);

  const BankMethodItem = ({ bankItem, index }) => {
    if (editing) {
      return (
        <>
          {bankItem.type == "add-bank" ? (
            <CCol xs="auto"></CCol>
          ) : (
            <CCol
              xs="auto"
              className="text-body align-items-center pr-1 my-1"
              key={`method-option-${bankItem.id}`}
            >
              <CButton
                color="danger"
                variant="outline"
                shape="pill"
                className="font-weight-bold w-100 px-4"
                onClick={() => setArchiveSelection(bankItem)}
              >
                <CIcon
                  key={`icon-${bankItem.id}`}
                  title={`${bankItem.type}-icon-${index}`}
                  content={bankItem.icon}
                  alt={`${bankItem.type}-${index}-icon`}
                  size={"lg"}
                />
                &nbsp;
                <span>{bankItem.description}</span>
                &nbsp;
                <CIcon
                  key={`archive-icon-${bankItem.id}`}
                  title={`${bankItem.type}-archive-icon-${index}`}
                  content={freeSet.cilXCircle}
                  size={"xl"}
                  className="ml-2"
                />
              </CButton>
            </CCol>
          )}
        </>
      );
    }

    return (
      <>
        <CCol
          xs="auto"
          className="text-body align-items-center pr-1 my-1"
          key={`method-option-${bankItem.id}`}
        >
          <CButton
            color="primary"
            variant="outline"
            shape="pill"
            className="font-weight-bold w-100 px-4"
            onClick={() => handleOptionSelected(bankItem)}
          >
            <CIcon
              key={`icon-${bankItem.id}`}
              title={`${bankItem.type}-icon-${index}`}
              content={bankItem.icon}
              alt={`${bankItem.type}-${index}-icon`}
              size={"lg"}
            />
            &nbsp;
            <span>{bankItem.description}</span>
          </CButton>
        </CCol>
      </>
    );
  };

  const CardMethodItem = ({ cardItem, index }) => {
    if (editing) {
      return (
        <>
          {cardItem.type == "add-card" ? (
            <CCol xs="auto"></CCol>
          ) : (
            <CCol
              xs="auto"
              className="text-body align-items-center pr-1 my-1"
              key={`method-option-${cardItem.id}`}
            >
              <CButton
                color="danger"
                variant="outline"
                shape="pill"
                className="font-weight-bold w-100 px-4"
                onClick={() => setArchiveSelection(cardItem)}
              >
                <CIcon
                  key={`icon-${cardItem.id}`}
                  title={`${cardItem.type}-icon-${index}`}
                  content={cardItem.icon}
                  alt={`${cardItem.type}-${index}-icon`}
                  size={"lg"}
                />
                &nbsp;
                <span>{cardItem.description}</span>
                &nbsp;
                <CIcon
                  key={`archive-icon-${cardItem.id}`}
                  title={`${cardItem.type}-archive-icon-${index}`}
                  content={freeSet.cilXCircle}
                  size={"xl"}
                  className="ml-2"
                />
              </CButton>
            </CCol>
          )}
        </>
      );
    }

    return (
      <CCol
        xs="auto"
        className="text-body align-items-center pr-1 my-1"
        key={`method-option-${cardItem.id}`}
      >
        <CButton
          color="primary"
          variant="outline"
          shape="pill"
          className="font-weight-bold w-100 px-4"
          onClick={() => handleOptionSelected(cardItem)}
        >
          <CIcon
            key={`icon-${cardItem.id}`}
            title={`${cardItem.type}-icon-${index}`}
            content={cardItem.icon}
            alt={`${cardItem.type}-${index}-icon`}
            size={"lg"}
          />
          &nbsp;
          <span>{cardItem.description}</span>
        </CButton>
      </CCol>
    );
  };

  const archivePaymentMethod = async () => {
    try {
      setIsArchivingSelection(true);
      await firebase.archivePaymentMethod(archiveSelection);

      // if is autopayment method, turn autopay off
      if (archiveSelection.path === paymentAccount.autopayMethodPath)
        await firebase.turnAutopayOffForAccount(paymentAccount.path);

      toast.success("Payment method successfully archived!");
    } catch (err) {
      console.error(err);
    } finally {
      setIsArchivingSelection(false);
      setArchiveSelection(null);
    }
  };

  const [bankMethods, setBankMethods] = useState(null);
  useEffect(() => {
    if (!options) return;
    const updated = options.filter((method) => method.type === "bank");
    const addBankOption = {
      id: "add-bank",
      type: "add-bank",
      icon: freeSet.cilPlus,
      description: "Add New Bank",
    };
    updated.push(addBankOption);
    setBankMethods(updated);
  }, [options]);

  const [cardMethods, setCardMethods] = useState(null);
  useEffect(() => {
    if (!options) return;
    const updated = options.filter((method) => method.type === "card");
    const addCardOption = {
      id: "add-card",
      type: "add-card",
      icon: freeSet.cilPlus,
      description: "Add New Card",
    };
    updated.push(addCardOption);
    setCardMethods(updated);
  }, [options]);

  const [bankLinkToken, setBankLinkToken] = useState(null);
  const [linking, setLinking] = useState(false);
  const [showNewBankForm, setShowNewBankForm] = useState(false);
  const handleOptionSelected = (selection) => {
    switch (selection.id) {
      case "add-bank":
        setShowNewBankForm(true);
        break;
      default:
        setShowNewBankForm(false);
        setLinking(false);
        setBankLinkToken(null);
        handleSelection(selection);
        break;
    }
  };

  const getContactEmail = () => {
    let email = contactEmail;
    if (isLocal()) {
      const testContactEmailArr = email.split("@");
      email = `${testContactEmailArr[0]}+test_email@${testContactEmailArr[1]}`;
    }

    return email;
  };

  const createLinkToken = useCallback(async () => {
    setLinking(true);
    try {
      const { data } = await firebase.createBankLinkToken(
        paymentAccount.customer,
        paymentAccount.id
      );
      setBankLinkToken(data);
    } catch (err) {
      setLinking(false);
      console.error(err);
    }
  }, [setBankLinkToken]);

  useEffect(() => {
    if (bankLinkToken !== null) return;
    setLinking(false);
  }, [bankLinkToken]);

  /**
   * Adding Legacy Routing & Account Numbers
   *
   * */

  const [bankRoutingNumber, setBankRoutingNumber] = useState("");
  const [confirmRoutingNumber, setConfirmRoutingNumber] = useState("");
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [confirmAccountNumber, setConfirmAccountNumber] = useState("");
  const [bankAccountType, setBankAccountType] = useState("checking");
  const formLabelClasses = "font-weight-bold text-uppercase small mb-2 mt-3";
  const linkLegacyBankAccount = async () => {
    // console.log(
    //   "linkLegacyBankAccount",
    //   bankRoutingNumber,
    //   bankAccountNumber,
    //   bankAccountType
    // );

    setLinking(true);
    try {
      const { data } = await firebase.createBankLinkWithNumbers(
        bankAccountNumber,
        bankRoutingNumber,
        bankAccountType
      );
      const { accessToken, accountId } = data;

      const response = await firebase.getBankAuthData(accessToken);

      const itemId = response.data.item.item_id;
      const bankData = {
        accounts: response.data.accounts,
        institution: {
          name: null,
        },
        accountNumber: bankAccountNumber,
        routingNumber: bankRoutingNumber,
      };
      await firebase.recordNewBankPaymentMethod(
        paymentAccount,
        bankData,
        accessToken,
        itemId,
        contactEmail
      );
      toast.success("Bank account successfully linked!");
    } catch (err) {
      //console.log(err);
      toast.error("Error linking bank account. Check your input and try again");
    } finally {
      setLinking(false);
    }
  };

  const [bankAddMethodSelection, setBankAddMethodSelection] = useState(
    "plaid-link"
  );
  const plaidLinkCallback = () => {
    setBankLinkToken(null);
  };

  if (!bankMethods || !cardMethods) return <InlineLoading />;

  if (bankLinkToken)
    return (
      <>
        <InlineLoading />
        <PlaidLink
          token={bankLinkToken}
          account={paymentAccount}
          contactEmail={contactEmail}
          callback={plaidLinkCallback}
        />
      </>
    );

  if (!usesPlaidLink && showNewBankForm)
    return (
      <>
        <CRow className="align-items-top">
          <CCol>
            <CLabel
              htmlFor="payment-method-selector"
              className="font-weight-bold"
            >
              Add a bank account
            </CLabel>
          </CCol>
          <CCol className="text-right">
            <CButton
              color="link"
              className="p-0 border-0 font-weight-bold"
              onClick={() => setShowNewBankForm(false)}
            >
              Go back
            </CButton>
          </CCol>
        </CRow>
        <CRow className="shadow-sm border mx-n2 font-weight-bold align-items-center rounded-lg pb-3 mt-2 text-body">
          <CCol>
            <CRow className="mt-3">
              <CCol xs="auto">
                <CLabel
                  htmlFor="select-pay-method"
                  className="m-0 font-weight-bold"
                >
                  Enter bank information
                </CLabel>
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <CLabel
                  htmlFor="bankroutingnumber-input"
                  className={formLabelClasses}
                >
                  Bank Routing Number (9 digits)
                </CLabel>
                <CInput
                  id="bankroutingnumber-input"
                  value={bankRoutingNumber}
                  onChange={(event) => setBankRoutingNumber(event.target.value)}
                  className={
                    bankRoutingNumber.length === 9 ||
                    bankRoutingNumber.length === 0
                      ? "text-body"
                      : "text-body is-invalid"
                  }
                />
                <p
                  className={
                    bankRoutingNumber.length === 9 ||
                    bankRoutingNumber.length === 0
                      ? "d-none"
                      : "invalid-feedback m-0"
                  }
                >
                  Routing number must be 9 digits long
                </p>
              </CCol>
              <CCol>
                <CLabel
                  htmlFor="confirmroutingnumber-input"
                  className={formLabelClasses}
                >
                  Confirm Routing Number
                </CLabel>
                <CInput
                  id="confirmroutingnumber-input"
                  value={confirmRoutingNumber}
                  onChange={(event) =>
                    setConfirmRoutingNumber(event.target.value)
                  }
                  className={
                    confirmRoutingNumber === bankRoutingNumber
                      ? "text-body"
                      : "is-invalid text-body"
                  }
                />
                <p
                  className={
                    confirmRoutingNumber === bankRoutingNumber
                      ? "d-none"
                      : "invalid-feedback m-0"
                  }
                >
                  Routing numbers do not match
                </p>
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <CLabel
                  htmlFor="bankaccountnumber-input"
                  className={formLabelClasses}
                >
                  Bank Account Number
                </CLabel>
                <CInput
                  id="bankaccountnumber-input"
                  value={bankAccountNumber}
                  onChange={(event) => setBankAccountNumber(event.target.value)}
                  className="text-body"
                />
              </CCol>
              <CCol>
                <CLabel
                  htmlFor="confirmaccountnumber-input"
                  className={formLabelClasses}
                >
                  Confirm Account Number
                </CLabel>
                <CInput
                  id="confirmaccountnumber-input"
                  value={confirmAccountNumber}
                  onChange={(event) =>
                    setConfirmAccountNumber(event.target.value)
                  }
                  className={
                    confirmAccountNumber === bankAccountNumber
                      ? "text-body"
                      : "is-invalid text-body"
                  }
                />
                <p
                  className={
                    confirmAccountNumber === bankAccountNumber
                      ? "d-none"
                      : "invalid-feedback m-0"
                  }
                >
                  Account numbers do not match
                </p>
              </CCol>
            </CRow>
            <RadioSelectionGroup
              label="Account Type"
              name="bank-account-type-select"
              options={[
                {
                  text: "Checking",
                  value: "checking",
                },
                {
                  text: "Savings",
                  value: "savings",
                },
              ]}
              selection={bankAccountType}
              handleSelectionChange={setBankAccountType}
              labelClasses={formLabelClasses}
            />
            <CRow className="pt-2 mt-2 align-items-center">
              <CCol xs="auto">
                <SubmitBtn
                  cta="Add Bank"
                  callback={linkLegacyBankAccount}
                  isLoading={linking}
                  disabledWhen={
                    bankRoutingNumber.length !== 9 ||
                    bankAccountNumber === "" ||
                    bankAccountNumber !== confirmAccountNumber ||
                    confirmRoutingNumber !== bankRoutingNumber
                  }
                />
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </>
    );

  if (usesPlaidLink && showNewBankForm)
    return (
      <>
        <CRow className="align-items-top mb-3">
          <CCol xs="auto" className="ml-n4">
            <CLabel
              htmlFor="payment-method-selector"
              className="font-weight-bold bg-light text-body rounded-lg pr-3 py-1 pl-3"
            >
              Add a bank account
            </CLabel>
          </CCol>
          <CCol className="text-right">
            <CButton
              color="link"
              className="px-0 font-weight-bold"
              onClick={() => setShowNewBankForm(false)}
            >
              Go back
            </CButton>
          </CCol>
        </CRow>
        <CRow>
          <CCol xs="7">
            <CFormGroup variant="custom-radio">
              <CInputRadio
                custom
                id="radio-bank-method-plaid-link"
                name="radio-bank-method-plaid-link"
                value="plaid-link"
                checked={bankAddMethodSelection === "plaid-link"}
                onChange={(e) => setBankAddMethodSelection(e.target.value)}
              />
              <CLabel
                variant="custom-checkbox"
                htmlFor="radio-bank-method-plaid-link"
                className={
                  bankAddMethodSelection === "plaid-link"
                    ? "font-weight-bold"
                    : ""
                }
              >
                Link with Plaid (recommended)
              </CLabel>
              {bankAddMethodSelection === "plaid-link" && (
                <p className="mb-0">
                  Uses your banking credentials to quickly & securely link your
                  bank account
                </p>
              )}
            </CFormGroup>
          </CCol>
          {bankAddMethodSelection === "plaid-link" && (
            <CCol>
              <SubmitBtn
                cta="Continue"
                callback={createLinkToken}
                isLoading={linking}
                classes="font-weight-bold shadow text-nowrap px-5"
              />
            </CCol>
          )}
        </CRow>
        <CRow>
          <CCol>
            <hr className="border border-light" />
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CFormGroup variant="custom-radio">
              <CInputRadio
                custom
                id="radio-bank-method-manual"
                name="radio-bank-method-manual"
                value="manual"
                checked={bankAddMethodSelection === "manual"}
                onChange={(e) => setBankAddMethodSelection(e.target.value)}
              />
              <CLabel
                variant="custom-checkbox"
                htmlFor="radio-bank-method-manual"
                className={
                  bankAddMethodSelection === "manual" ? "font-weight-bold" : ""
                }
              >
                Link with routing & account numbers
              </CLabel>
            </CFormGroup>
          </CCol>
        </CRow>
        {bankAddMethodSelection === "manual" && (
          <CRow className="shadow-sm border mx-n2 font-weight-bold align-items-center rounded-lg pb-3 mt-2 text-body">
            <CCol>
              <CRow className="mt-3">
                <CCol xs="auto">
                  <CLabel
                    htmlFor="select-pay-method"
                    className="m-0 font-weight-bold"
                  >
                    Enter bank information
                  </CLabel>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <CLabel
                    htmlFor="bankroutingnumber-input"
                    className={formLabelClasses}
                  >
                    Bank Routing Number (9 digits)
                  </CLabel>
                  <CInput
                    id="bankroutingnumber-input"
                    value={bankRoutingNumber}
                    onChange={(event) =>
                      setBankRoutingNumber(event.target.value)
                    }
                    className={
                      bankRoutingNumber.length === 9 ||
                      bankRoutingNumber.length === 0
                        ? "text-body"
                        : "text-body is-invalid"
                    }
                  />
                  <p
                    className={
                      bankRoutingNumber.length === 9 ||
                      bankRoutingNumber.length === 0
                        ? "d-none"
                        : "invalid-feedback m-0"
                    }
                  >
                    Routing number must be 9 digits long
                  </p>
                </CCol>
                <CCol>
                  <CLabel
                    htmlFor="confirmroutingnumber-input"
                    className={formLabelClasses}
                  >
                    Confirm Routing Number
                  </CLabel>
                  <CInput
                    id="confirmroutingnumber-input"
                    value={confirmRoutingNumber}
                    onChange={(event) =>
                      setConfirmRoutingNumber(event.target.value)
                    }
                    className={
                      confirmRoutingNumber === bankRoutingNumber
                        ? "text-body"
                        : "is-invalid text-body"
                    }
                  />
                  <p
                    className={
                      confirmRoutingNumber === bankRoutingNumber
                        ? "d-none"
                        : "invalid-feedback m-0"
                    }
                  >
                    Routing numbers do not match
                  </p>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <CLabel
                    htmlFor="bankaccountnumber-input"
                    className={formLabelClasses}
                  >
                    Bank Account Number
                  </CLabel>
                  <CInput
                    id="bankaccountnumber-input"
                    value={bankAccountNumber}
                    onChange={(event) =>
                      setBankAccountNumber(event.target.value)
                    }
                    className="text-body"
                  />
                </CCol>
                <CCol>
                  <CLabel
                    htmlFor="confirmaccountnumber-input"
                    className={formLabelClasses}
                  >
                    Confirm Account Number
                  </CLabel>
                  <CInput
                    id="confirmaccountnumber-input"
                    value={confirmAccountNumber}
                    onChange={(event) =>
                      setConfirmAccountNumber(event.target.value)
                    }
                    className={
                      confirmAccountNumber === bankAccountNumber
                        ? "text-body"
                        : "is-invalid text-body"
                    }
                  />
                  <p
                    className={
                      confirmAccountNumber === bankAccountNumber
                        ? "d-none"
                        : "invalid-feedback m-0"
                    }
                  >
                    Account numbers do not match
                  </p>
                </CCol>
              </CRow>
              <RadioSelectionGroup
                label="Account Type"
                name="bank-account-type-select"
                options={[
                  {
                    text: "Checking",
                    value: "checking",
                  },
                  {
                    text: "Savings",
                    value: "savings",
                  },
                ]}
                selection={bankAccountType}
                handleSelectionChange={setBankAccountType}
                labelClasses={formLabelClasses}
              />
              <CRow className="pt-2 mt-2 align-items-center">
                <CCol xs="auto">
                  <SubmitBtn
                    cta="Add Bank"
                    callback={linkLegacyBankAccount}
                    isLoading={linking}
                    disabledWhen={
                      bankRoutingNumber.length !== 9 ||
                      bankAccountNumber === "" ||
                      bankAccountNumber !== confirmAccountNumber ||
                      confirmRoutingNumber !== bankRoutingNumber
                    }
                  />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        )}
      </>
    );

  return (
    <>
      <CRow>
        <CCol>
          <CLabel
            htmlFor="select-pay-method"
            className={`m-0 font-weight-bold ${showHeader ? "" : "d-none"}`}
          >
            Select a payment method
          </CLabel>
          {methods && methods.length > 0 && allowPayMethodEditing && (
            <span>
              &nbsp; [
              {editing ? (
                <CButton
                  color="link"
                  className="m-0 p-0 align-baseline font-weight-bold"
                  onClick={() => setEditing(false)}
                >
                  Done
                </CButton>
              ) : (
                <CButton
                  color="link"
                  className="m-0 p-0 align-baseline font-weight-bold"
                  onClick={() => setEditing(true)}
                >
                  Edit
                </CButton>
              )}
              ]
            </span>
          )}
        </CCol>
      </CRow>
      <CRow className="py-3">
        {bankMethods.map((opt, i) => (
          <BankMethodItem
            bankItem={opt}
            index={i}
            key={`bank-method-item-${opt.id}`}
          />
        ))}
      </CRow>
      {showCardOptions && cardMethods.length > 0 && (
        <CRow className="pt-3 border-top">
          {cardMethods.map((opt, i) => (
            <CardMethodItem
              cardItem={opt}
              index={i}
              key={`card-method-item-${opt.id}`}
            />
          ))}
        </CRow>
      )}
      {archiveSelection && (
        <ConfirmationDialog
          name="confirm-archive-method-dialog"
          isOpen={archiveSelection !== null && archivePaymentMethodMsg !== ""}
          isLoading={isArchivingSelection}
          header="Remove Payment Method?"
          body={archivePaymentMethodMsg}
          primaryCtaText="Confirm"
          primaryCtaColor="danger"
          handleConfirm={archivePaymentMethod}
          handleClose={() => setArchiveSelection(null)}
        />
      )}
    </>
  );
};

export const RadioSelectionGroup = ({
  label,
  name,
  options,
  selection,
  handleSelectionChange,
  inline = true,
  disabledWhen = false,
  labelClasses = "mb-1 font-weight-bold text-muted",
  textClasses = "",
}) => {
  return (
    <CRow className="pb-2">
      <CCol>
        <p className={labelClasses}>{label}</p>
        {options.map((opt, index) => (
          <CFormGroup
            key={`radiogroup-${name}-${index}`}
            variant="custom-radio"
            inline={inline}
          >
            <CInputRadio
              custom
              id={`radio-${name}-${index}`}
              name={`radio-${name}-${index}`}
              key={`radio-${name}-${index}`}
              value={opt.value}
              checked={selection === opt.value}
              disabled={disabledWhen}
              onChange={(e) => handleSelectionChange(e.target.value)}
            />
            <CLabel
              variant="custom-checkbox"
              htmlFor={`radio-${name}-${index}`}
              key={`label-${name}-${index}`}
              className={
                opt.value === selection
                  ? `${textClasses} font-weight-bold`
                  : textClasses
              }
            >
              {opt.text}
            </CLabel>
          </CFormGroup>
        ))}
      </CCol>
    </CRow>
  );
};

export const LabeledSection = ({ label, value, classes }) => {
  if (!value) return null;

  //console.log(value);

  return (
    <>
      <CRow className="my-3">
        <CCol>
          <small className="text-muted">{label}</small>
          <br />
          <strong className={classes}>{value}</strong>
          <br />
        </CCol>
      </CRow>
    </>
  );
};

export const BadgeSection = ({ label, value }) => {
  if (!value) return null;

  return (
    <>
      <CRow className="my-3">
        <CCol>
          <small className="text-muted">{label}</small>
          <br />
          <CBadge color={badgeColor(value)}>{value}</CBadge>
          <br />
        </CCol>
      </CRow>
    </>
  );
};

export const InlineNotification = ({ message, type }) => {
  const NOTIF_CLASSES = {
    info:
      "font-weight-bold bg-info text-white mx-n2 rounded-lg shadow-sm align-items-center text-center py-3",
    muted:
      "font-weight-bold bg-light text-dark mx-n2 rounded-lg shadow-sm align-items-center py-2",
    danger:
      "font-weight-bold bg-danger text-white mx-n2 rounded-lg shadow-sm align-items-center text-center py-3",
  };

  return (
    <CRow className={NOTIF_CLASSES[type]}>
      <CCol>
        <span>{message}</span>
      </CCol>
    </CRow>
  );
};

export const Loading = ({ isCentered = true }) => {
  const classes = isCentered
    ? "c-app c-default-layout flex-row align-items-center"
    : "c-app c-default-layout flex-row";
  return (
    <div className={classes}>
      <CContainer>
        <CRow className="justify-content-center">
          <div className="spinner-border" role="status" aria-label="loading">
            <span className="sr-only">Loading...</span>
          </div>
        </CRow>
      </CContainer>
    </div>
  );
};

export const InlineLoading = () => (
  <CRow className="justify-content-center">
    <div
      className="spinner-border spinner-border-sm"
      role="status"
      aria-label="loading"
    >
      <span className="sr-only">Loading...</span>
    </div>
  </CRow>
);

export const SubmitBtn = ({
  cta,
  callback,
  isLoading,
  isBlockBtn = false,
  disabledWhen = false,
  classes = "",
  variant = "",
  type = "primary",
}) => {
  if (isLoading)
    return (
      <CButton
        color={type}
        shape="pill"
        variant={variant}
        block={isBlockBtn}
        disabled={true}
        className={classes}
      >
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
      </CButton>
    );

  return (
    <CButton
      type="submit"
      color={type}
      shape="pill"
      variant={variant}
      block={isBlockBtn}
      disabled={disabledWhen}
      onClick={callback}
      className={classes}
    >
      <b>{cta}</b>
    </CButton>
  );
};

export const AccountNumberField = ({
  label,
  value,
  customerName,
  handleValueChange,
  account = null,
  handleAccountChange = undefined,
  index = 0,
  widthClass = "w-100",
  showAccountDetails = false,
}) => {
  const firebase = useContext(FirebaseContext);

  //const [account, setAccount] = useState(null);
  const [accountName, setAccountName] = useState(null);
  const [accountEntryError, setAccountEntryError] = useState(null);

  const handleAccountRetrieved = (account) => {
    if (!account) {
      setAccountEntryError("Account # not found");
      return;
    }

    if (handleAccountChange !== undefined) handleAccountChange(account);

    setAccountName(account.name);
  };
  useEffect(() => {
    if (!value || !value.length) return;

    if (!isValidAccountNumber(value, customerName)) {
      if (handleAccountChange !== undefined) handleAccountChange(null);

      setAccountName(null);
      setAccountEntryError("Account ID is not 8 digits long");
      return;
    }

    setAccountEntryError("");
    //console.log("searching for payer account with id", value);
    firebase.getAccountByPath(
      `customers/${customerName}/accounts/${value}`,
      handleAccountRetrieved
    );
  }, [value, customerName]);

  const [showBalanceDetails, setShowBalanceDetails] = useState(false);
  useEffect(() => {
    if (!account) setShowBalanceDetails(false);
  }, [account]);

  return (
    <>
      <CRow>
        <CCol>
          <CLabel
            htmlFor={`${label}-${index}-input`}
            className="font-weight-bold text-uppercase small"
          >
            {label}
          </CLabel>
          <CInput
            id={`${label}-${index}-input`}
            value={value}
            onChange={(event) => handleValueChange(event.target.value)}
            className={
              isValidAccountNumber(value, customerName) &&
              accountEntryError === ""
                ? `text-body ${widthClass}`
                : `text-body is-invalid ${widthClass}`
            }
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol xs="auto">
          <p
            className={
              isValidAccountNumber(value, customerName) &&
              accountEntryError === ""
                ? "mt-1 mb-0 small text-uppercase font-weight-bold text-truncate"
                : "invalid-feedback m-0 text-truncate"
            }
          >
            {isValidAccountNumber(value, customerName) &&
            accountEntryError === ""
              ? accountName
              : accountEntryError}
          </p>
        </CCol>
      </CRow>
      {account && (
        <>
          <CRow className="mt-n2">
            <CCol>
              <CRow>
                <CCol>
                  <LabeledSection
                    label="Current"
                    value={formatAsMoney(account.current_balance / 100)}
                  />
                </CCol>
                <CCol>
                  <LabeledSection
                    label="Period 1"
                    value={formatAsMoney(account.period_one_balance / 100)}
                  />
                </CCol>
                <CCol>
                  <LabeledSection
                    label="Period 2"
                    value={formatAsMoney(account.period_two_balance / 100)}
                  />
                </CCol>
                <CCol>
                  <LabeledSection
                    label="Period 3"
                    value={formatAsMoney(account.period_three_balance / 100)}
                  />
                </CCol>
                <CCol>
                  <LabeledSection
                    label="Period 4"
                    value={formatAsMoney(account.period_four_balance / 100)}
                  />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </>
      )}
    </>
  );
};

export const InvoiceLookup = ({
  invoices,
  selectedInvoices,
  customerName,
  handleSelection,
  handleSelectionRemoved,
}) => {
  const firebase = useContext(FirebaseContext);
  const inputRef = useRef();

  const [items, setItems] = useState([]);
  const [sourceList, setSourceList] = useState([]);
  const [inputVal, setInputVal] = useState("");
  useEffect(() => {
    if (!invoices || !invoices.length) return;
    const copy = invoices.slice();
    setSourceList(copy);
  }, [invoices]);

  useEffect(() => {
    if (!sourceList) return;
    setItems(sourceList);
  }, [sourceList]);

  const [showNewRow, setShowNewRow] = useState(null);

  const addInvoice = (invoice) => {
    const copy = selectedInvoices.slice();
    let invToAdd;
    if (!invoice) {
      toast.warn(
        "Invoice number not found. Please enter invoice information manually."
      );
      invToAdd = {
        id: inputVal,
        path: "",
        amount: 0,
        account_id: "",
        payer_id: "",
        balance: null,
      };
    } else {
      invToAdd = {
        ...invoice,
        payAmount: invoice.balance,
        origAccountId: invoice.account_id,
        payer_id: invoice.account_id,
      };
      const itemsCopy = sourceList.slice();
      const index = sourceList.findIndex((el) => el.id === invoice.id);
      itemsCopy.splice(index, 1);
      setSourceList(itemsCopy);
    }

    //console.log(invToAdd);

    const updatedSelectedInvoices = [...copy, invToAdd];
    //console.log(updatedSelectedInvoices);
    handleSelection(updatedSelectedInvoices);

    setShowNewRow(false);
    reset();
  };

  const removeInvoice = (index) => {
    const updated = selectedInvoices.slice();
    const removedItem = updated[index];
    updated.splice(index, 1);

    handleSelection(updated);
    handleSelectionRemoved(removedItem);

    // add the removed item back to the items
    // array so that user can add it again
    const itemsCopy = sourceList.slice();
    const itemsUpdated = [...itemsCopy, removedItem].sort(
      (a, b) => parseInt(a) - parseInt(b)
    );
    setSourceList(itemsUpdated);

    // if there are no selected invoices,
    // show the invoice number entry field
    if (updated.length === 0) setShowNewRow(true);
  };

  const doInvoiceSearch = () => {
    firebase.getInvoiceById(inputVal, addInvoice);
    //reset();
  };

  const handlePayerAccountNumberChange = (inv, index, value) => {
    const copy = selectedInvoices.slice();
    const updated = {
      ...inv,
      payer_id: value,
    };
    copy[index] = updated;
    handleSelection(copy);
  };

  const handleAccountNumberChange = (inv, index, value) => {
    const copy = selectedInvoices.slice();
    const updated = {
      ...inv,
      account_id: value,
    };
    copy[index] = updated;
    handleSelection(copy);
  };

  const handlePayAmountChange = (inv, index, value) => {
    const copy = selectedInvoices.slice();
    const updated = { ...inv, payAmount: value };
    copy[index] = updated;
    handleSelection(copy);
  };

  const filterItems = (query) => {
    return sourceList.filter((item) => item.id.startsWith(query)).slice(0, 5);
  };

  const stateReducer = (state, actionAndChanges) => {
    const { type, changes } = actionAndChanges;

    switch (type) {
      case useCombobox.stateChangeTypes.ItemClick:
        addInvoice(changes.selectedItem);
        return {
          ...changes,
          ...(changes.selectedItem && {
            inputValue: "",
          }),
        };
      default:
        return changes;
    }
  };

  const {
    isOpen,
    getLabelProps,
    getComboboxProps,
    getInputProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
    reset,
  } = useCombobox({
    onSelectedItemChange: ({ selectedItem }) => {
      addInvoice(selectedItem);
    },
    onInputValueChange: ({ inputValue: newValue }) => {
      setInputVal(newValue);
      setItems(filterItems(newValue));
    },
    stateReducer,
    items,
    itemToString: (item) => {
      return item ? item.id : "";
    },
  });

  const handleKeyStrokes = ({ event }) => {
    const keycode = event.keyCode || event.which;
    if (keycode === Keys.RETURN) {
      event.preventDefault();
      doInvoiceSearch();
    }
  };

  // Prevents downshift errors that occur
  // when conditionally rending the field
  const [classes, setClasses] = useState("");
  useEffect(() => {
    if (!showNewRow) setClasses("d-none");
    else setClasses("");
  }, [showNewRow]);

  useEffect(() => {
    if (classes === "") {
      inputRef.current.focus();
    }
  }, [classes]);

  return (
    <>
      {selectedInvoices.map((inv, i) => (
        <CRow key={`${inv.id}-${i}`} className="align-items-start px-1 my-4">
          <CCol xs="auto" className="font-weight-bold bg-light pb-2">
            <p className="my-1 px-n2">{i + 1}</p>
          </CCol>
          <CCol className="mt-1">
            <p className="font-weight-bold text-uppercase small">Inv #</p>
            <p>{inv.id}</p>
          </CCol>
          <CCol className="px-2 mt-1">
            <p className="font-weight-bold text-uppercase small">Balance</p>
            <p>
              {!inv.balance ? "--" : formatAsMoney(inv.balance / 100) || ""}
            </p>
          </CCol>
          <CCol className="px-1">
            <AccountNumberField
              label="Payer Account #"
              value={inv.payer_id}
              customerName={customerName}
              handleValueChange={(newValue) =>
                handlePayerAccountNumberChange(inv, i, newValue)
              }
              index={i}
            />
          </CCol>
          <CCol className="px-1">
            <AccountNumberField
              label="Account #"
              value={inv.account_id}
              customerName={customerName}
              handleValueChange={(newValue) =>
                handleAccountNumberChange(inv, i, newValue)
              }
              index={i}
            />
          </CCol>
          <CCol className="px-1">
            <CLabel
              htmlFor={`inv-apply-amount-${inv.id}`}
              className="font-weight-bold text-uppercase small mb-2"
            >
              Amount to apply
            </CLabel>
            <NumericFormat
              id={`inv-apply-amount-${inv.id}`}
              placeholder="$0"
              prefix={"$"}
              className="form-control text-body"
              decimalScale={2}
              valueIsNumericString={true}
              value={inv.payAmount / 100}
              onValueChange={(values) =>
                handlePayAmountChange(inv, i, values.floatValue * 100)
              }
            />
          </CCol>
          <CCol xs="auto" className="mt-4 pt-2">
            <CIcon
              title={`remove-button-icon-${inv.id}`}
              content={freeSet.cilXCircle}
              key={`remove-button-icon-${inv.id}`}
              className="text-danger"
              style={{ cursor: "pointer" }}
              onClick={() => removeInvoice(i)}
            />
          </CCol>
        </CRow>
      ))}
      {/*** Invoice Entry ***/}
      <CRow className={`mt-3 mb-1 px-2 ${classes}`}>
        <CCol xs="4" className="bg-light text-body px-3 pt-2 pb-3 rounded-lg">
          <div>
            <small>
              <label
                {...getLabelProps()}
                className="font-weight-bold text-uppercase"
              >
                Find an open invoice
              </label>
            </small>
            <div {...getComboboxProps()}>
              <input
                {...getInputProps({
                  ref: inputRef,
                  onKeyDown: (event) => {
                    handleKeyStrokes({ event });
                  },
                  placeholder: "Enter Invoice #",
                  className: "form-control text-body",
                  maxLength: 7,
                })}
              />
              {items.length === 0 && (
                <CButton
                  color="primary"
                  variant="outline"
                  shape="pill"
                  className="mt-2 float-right font-weight-bold"
                  onClick={() => doInvoiceSearch()}
                >
                  Search
                </CButton>
              )}
            </div>
            <ul {...getMenuProps()} className="list-group bg-white">
              {isOpen &&
                items.map((item, index) => (
                  <li
                    className="list-group-item"
                    style={{
                      backgroundColor:
                        highlightedIndex === index ? "#bde4ff" : "",
                      cursor: "pointer",
                    }}
                    key={`${item}-${index}`}
                    {...getItemProps({ item, index })}
                  >
                    {item.id}
                  </li>
                ))}
            </ul>
          </div>
        </CCol>
      </CRow>
      {!showNewRow && (
        <CRow className="my-2 px-3">
          <CCol>
            <CButton
              color="link"
              className="px-0 mx-n3"
              onClick={() => setShowNewRow(true)}
            >
              + Add an invoice
            </CButton>
          </CCol>
        </CRow>
      )}
    </>
  );
};

/**
 * Email Composition Components
 * ~~~~~~~~~~~~~~~~~~
 *
 * */

const Recipient = ({ children, isValid, onRemove }) => {
  const button = useRef();
  const focusButton = (event) => {
    button.current.focus();
  };

  return (
    <div
      ref={button}
      onClick={focusButton}
      className="px-3 py-1 mr-1"
      style={{
        backgroundColor: "#f5f5f5",
        fontSize: "0.9em",
        border: "1px solid",
        borderColor: isValid ? "#d9d9d9" : "#d61111",
        borderRadius: 4,
        cursor: "pointer",
      }}
    >
      {children}
      {onRemove ? (
        <button
          aria-label={`remove-contact-${children}`}
          style={{
            WebkitAppearance: "none",
            marginLeft: 6,
            color: "#868686",
            backgroundColor: "#f5f5f5",
            border: "none",
            cursor: "pointer",
            padding: 2,
          }}
          onClick={onRemove}
        >
          X
        </button>
      ) : null}
    </div>
  );
};

export const SalesRepOnboardingSelector = ({
  label,
  salesreps,
  inputValue,
  handleInputValueChange,
  selection,
  onSelectionChange,
}) => {
  const [inputItems, setInputItems] = useState([]);
  const [list, setList] = useState([]);
  const input = useRef();

  useEffect(() => {
    if (!salesreps) return;
    const copy = salesreps.slice();
    setList(copy);
    setInputItems(copy.map((rep) => rep.name));
  }, [salesreps]);

  // Focuses on the field when user
  // clears the field
  useEffect(() => {
    if (!selection) input.current.focus();
  }, [selection]);

  // Prevents downshift errors that occur
  // when conditionally rending the field
  const [classes, setClasses] = useState("");
  useEffect(() => {
    if (selection) setClasses("d-none");
    else setClasses("");
  }, [selection]);

  const handleItemSelected = ({ selectedItem }) => {
    onSelectionChange(selectedItem);
    handleInputValueChange("");
    closeMenu();
  };

  const stateReducer = (state, actionAndChanges) => {
    const { type, changes } = actionAndChanges;
    //console.log(type);
    //console.log(changes);

    switch (type) {
      case useCombobox.stateChangeTypes.ItemClick:
        //console.log(selectedContacts);
        //console.log(changes.selectedItem);
        if (selection !== changes.selectedItem.name) {
          // TODO: causes a react warning; fix seems to be to wrap in an inEffect block (https://github.com/airbnb/lunar/commit/db08613d46ea21089ead3e7b5cfff995f15c69a7#diff-1c3bdd397b1ce5064142488877045306R56)
          handleItemSelected(changes);
        }
        return {
          ...changes,
          ...(changes.selectedItem && {
            inputValue: "",
          }),
        };
      default:
        return changes;
    }
  };

  const getItems = (query) => {
    return list
      .filter((item) => item.name.toLowerCase().includes(query.toLowerCase()))
      .slice(0, 5);
  };

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    getLabelProps,
    reset,
    closeMenu,
  } = useCombobox({
    items: inputItems,
    inputValue,
    //itemToString,
    stateReducer,
    onSelectedItemChange: handleItemSelected,
    onInputValueChange: ({ inputValue: newValue }) => {
      handleInputValueChange(newValue);
      setInputItems(getItems(newValue));
    },
  });

  const handleKeyStrokes = ({ event }) => {
    // Backspace key removes previous input
    let keycode = event.keyCode || event.which;
    //console.log(keycode, isOpen);
    if (
      inputValue !== "" &&
      [Keys.TAB, Keys.COMMA, Keys.SEMICOLON, Keys.RETURN].includes(keycode)
    ) {
      //console.log("key hit");
      event.preventDefault();

      // if the user has an item highlighted when they hit one of these keys, added to selected contacts list
      if (highlightedIndex !== -1) {
        handleItemSelected({ selectedItem: inputItems[highlightedIndex] });
      }
      // if the email address isn't in the list
      else {
        handleItemSelected({ selectedItem: { name: "", email: inputValue } });
      }
    }
  };

  return (
    <div>
      <small>
        <label {...getLabelProps()} className="font-weight-bold text-uppercase">
          {label}
        </label>
      </small>
      <div
        {...getComboboxProps()}
        style={{
          position: "relative",
          alignItems: "center",
          flexWrap: "wrap",
          display: "flex",
        }}
      >
        <CRow className="w-100">
          <CCol>
            {selection && (
              <>
                <span>{selection.name}</span>
                <CButton
                  color="link"
                  className="p-0 border-0 font-weight-bold ml-3 align-top text-body text-right"
                  onClick={() => onSelectionChange(null)}
                >
                  X
                </CButton>
              </>
            )}
            <CRow className={classes}>
              <CCol>
                <input
                  {...getInputProps({
                    ref: input,
                    onKeyDown: (event) => {
                      handleKeyStrokes({ event });
                    },
                    placeholder: "e.g. Jane Smith",
                    style: {
                      flex: 1,
                      border: "none",
                      outline: "none",
                      width: "100%",
                      minWidth: "100",
                    },
                    className: "py-3",
                  })}
                />
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </div>
      <ul
        {...getMenuProps({ style: { padding: 0, margin: 0 } })}
        className="list-group"
      >
        {isOpen &&
          inputItems.map((item, index) => (
            <li
              className="list-group-item"
              style={{
                backgroundColor: highlightedIndex === index ? "#bde4ff" : "",
                cursor: "pointer",
              }}
              key={`${item}${index}`}
              {...getItemProps({ item, index })}
            >
              {item.name}
            </li>
          ))}
      </ul>
    </div>
  );
};

export const EmailField = ({
  label,
  sending,
  inputValue,
  handleInputValueChange,
  selectedContacts,
  onSelectionChange,
  contacts,
}) => {
  const [inputItems, setInputItems] = useState([]);
  const [contactsList, setContactsList] = useState([]);
  const input = useRef();

  // if contacts is not null and not an empty array, load contacts into autocomplete list
  useEffect(() => {
    //console.log(contacts);
    if (contacts && contacts.length > 0) {
      //console.log("update contact list");
      let newContactsList = contacts.slice();
      setContactsList(newContactsList);
      setInputItems(contactsList.map((c) => c.email));
    }
  }, [contacts]);

  const removeContact = (contact) => {
    //console.log("removing selected contact");

    // Remove from selected contacts
    onSelectionChange(selectedContacts.filter((c) => c !== contact));

    // Focus cursor back to input field and close the menu
    input.current.focus();
    closeMenu();
  };

  const itemToString = (item) => {
    if (!item) return "";
    if (!item.name) return `${item.email}`;

    return `${item.name} (${item.email})`;
  };

  const handleSelectedItem = ({ selectedItem }) => {
    //console.log("adding selected contact");

    // Add selected item to selected contacts
    onSelectionChange([...selectedContacts, selectedItem]);

    // Close the menu
    closeMenu();
  };

  const stateReducer = (state, actionAndChanges) => {
    const { type, changes } = actionAndChanges;
    //console.log(type);
    //console.log(changes);

    switch (type) {
      case useCombobox.stateChangeTypes.ItemClick:
        //console.log(selectedContacts);
        //console.log(changes.selectedItem);
        if (
          !selectedContacts.some((i) => i.email === changes.selectedItem.email)
        ) {
          // TODO: causes a react warning; fix seems to be to wrap in an inEffect block (https://github.com/airbnb/lunar/commit/db08613d46ea21089ead3e7b5cfff995f15c69a7#diff-1c3bdd397b1ce5064142488877045306R56)
          handleSelectedItem(changes);
        }
        return {
          ...changes,
          ...(changes.selectedItem && {
            inputValue: "",
          }),
        };
      default:
        return changes;
    }
  };

  const getItems = (query) => {
    return contactsList
      .filter(
        (item) =>
          item.name.toLowerCase().includes(query.toLowerCase()) ||
          item.email.toLowerCase().includes(query.toLowerCase())
      )
      .slice(0, 5);
  };

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    getLabelProps,
    reset,
    closeMenu,
  } = useCombobox({
    items: inputItems,
    inputValue,
    itemToString,
    stateReducer,
    onSelectedItemChange: handleSelectedItem,
    onInputValueChange: ({ inputValue: newValue }) => {
      handleInputValueChange(newValue);
      setInputItems(getItems(newValue));
    },
  });

  const handleKeyStrokes = ({ event }) => {
    // Backspace key removes previous input
    let keycode = event.keyCode || event.which;
    //console.log(keycode, isOpen);
    if (keycode === Keys.BACKSPACE && !event.target.value) {
      onSelectionChange(
        selectedContacts.length
          ? selectedContacts.slice(0, selectedContacts.length - 1)
          : []
      );
    }
    // Tab, comma, and semi-colon adds input to the selected contacts list
    else if (
      inputValue !== "" &&
      [Keys.TAB, Keys.COMMA, Keys.SEMICOLON, Keys.RETURN].includes(keycode)
    ) {
      //console.log("key hit");
      event.preventDefault();

      // if the user has an item highlighted when they hit one of these keys, added to selected contacts list
      if (highlightedIndex !== -1) {
        handleSelectedItem({ selectedItem: inputItems[highlightedIndex] });
      }
      // if the email address isn't in the list
      else {
        handleSelectedItem({ selectedItem: { name: "", email: inputValue } });
      }
    }
  };

  //console.log(contactsList);
  //console.log(inputItems);
  //console.log(label);
  //console.log(selectedContacts);

  return (
    <div>
      <small>
        <label
          //htmlFor={label}
          {...getLabelProps()}
          className="font-weight-bold text-uppercase"
        >
          {label}
        </label>
      </small>
      <div
        {...getComboboxProps()}
        style={{
          position: "relative",
          alignItems: "center",
          flexWrap: "wrap",
          display: "flex",
        }}
      >
        {selectedContacts.map((c, i) => {
          if (!c) return null;
          if (!c.email) return null;
          return (
            <Recipient
              key={i}
              isValid={c.email.includes("@")}
              onRemove={() => removeContact(c)}
            >
              {itemToString(c)}
            </Recipient>
          );
        })}
        <input
          {...getInputProps({
            ref: input,
            onKeyDown: (event) => {
              handleKeyStrokes({ event });
            },
            placeholder: contacts
              ? Strings.MSG_EMAIL_PLACEHOLDER_CONTACTS
              : Strings.MSG_EMAIL_PLACEHOLDER_NO_CONTACTS,
            style: {
              flex: 1,
              border: "none",
              outline: "none",
              width: "100%",
              minWidth: "100",
            },
            className: "py-3",
          })}
        />
      </div>
      <ul
        {...getMenuProps({ style: { padding: 0, margin: 0 } })}
        className="list-group"
      >
        {isOpen &&
          inputItems.map((item, index) => (
            <li
              className="list-group-item"
              style={{
                backgroundColor: highlightedIndex === index ? "#bde4ff" : "",
                cursor: "pointer",
              }}
              key={`${item}${index}`}
              {...getItemProps({ item, index })}
            >
              {itemToString(item)}
            </li>
          ))}
      </ul>
    </div>
  );
};

export const TextAreaField = ({ label, message, handleInputChange }) => {
  return (
    <>
      {label && (
        <CRow className="mb-2">
          <CCol>
            <CLabel htmlFor="message-input">
              <small className="font-weight-bold text-uppercase">{label}</small>
            </CLabel>
          </CCol>
        </CRow>
      )}
      <CRow>
        <CCol>
          <CTextarea
            id="message-input"
            name="message-input"
            rows="6"
            value={message}
            style={{ color: "rgb(60, 75, 100)" }}
            onChange={(e) => handleInputChange(e.target.value)}
            placeholder={Strings.MSG_TEXT_PLACEHOLDER}
          />
        </CCol>
      </CRow>
    </>
  );
};

/***
 * PDF Tables
 * ~~~~~~~~~~~~~~
 *
 *
 * */

export const VerticalPDFTable = ({ name, keys, values, globalStyles }) => {
  const styles = StyleSheet.create({
    tableContainer: {
      flexWrap: "wrap",
    },
    headerCell: {
      fontWeight: 800,
      backgroundColor: "#e4e4e4",
      borderColor: "#e7e7e7",
      borderWidth: 1,
      flexBasis: 0,
      flexShrink: 1,
      flex: 1,
      flexGrow: 1,
      minWidth: 0,
      maxWidth: "20%",
      padding: 3,
      lineHeight: 1.7,
    },
    bodyCell: {
      flexDirection: "row",
      flexBasis: 0,
      flexGrow: 1,
      flexShrink: 1,
      flex: 1,
      minWidth: 0,
      maxWidth: "80%",
      padding: 3,
      lineHeight: 1.7,
    },
    tableRow: {
      flexDirection: "row",
      marginBottom: 2,
    },
    tableTitle: {
      fontStyle: "bold",
      marginBottom: 2,
      fontSize: 16,
    },
  });

  if (!keys || !values) return null;

  return (
    <>
      <View style={globalStyles.row}>
        <View style={{ ...globalStyles.col, paddingLeft: 0 }}>
          <Text style={styles.tableTitle}>{name}</Text>
        </View>
      </View>
      <View style={styles.tableContainer}>
        {keys.map((key, index) => (
          <View key={`row-${index}`} style={styles.tableRow}>
            <Text style={styles.headerCell}>{key}</Text>
            <Text style={styles.bodyCell}>{values[index]}</Text>
          </View>
        ))}
      </View>
    </>
  );
};

export const HorizontalPDFTable = ({ name, keys, data, globalStyles }) => {
  Font.registerHyphenationCallback((word) => [word]);
  const styles = StyleSheet.create({
    tableContainer: {
      flexDirection: "row",
      flexWrap: "wrap",
    },
    headerCell: {
      fontWeight: 800,
      backgroundColor: "#e4e4e4",
      borderColor: "#e7e7e7",
      borderWidth: 1,
    },
    bodyCell: {
      flexBasis: 0,
      flexShrink: 1,
      flex: 1,
      flexGrow: 1,
      textAlign: "left",
      wordWrap: "break-word",
      whiteSpace: "pre-wrap",
      marginRight: 2,
      lineHeight: 1.4,
      padding: 3,
    },
    tableRow: {
      display: "flex",
      flexDirection: "row",
      marginBottom: "2px",
      width: "100%",
      justifyContent: "space-between",
      flexGrow: 1,
      //alignItems: "center"
    },
    tableTitle: {
      fontStyle: "bold",
      marginBottom: 2,
      fontSize: 16,
    },
  });

  if (keys === null) return null;

  if (keys === undefined)
    return (
      <>
        <Text style={styles.tableTitle}>{name}</Text>
        <Text>No items display</Text>
      </>
    );

  return (
    <>
      <View style={globalStyles.row}>
        <View style={{ ...globalStyles.col, paddingLeft: 0 }}>
          <Text style={styles.tableTitle}>{name}</Text>
        </View>
      </View>
      <View style={{ ...styles.tableContainer, ...globalStyles[name] }}>
        <View style={styles.tableRow} wrap={false}>
          {keys.map((key) => (
            <Text
              key={key}
              style={{
                ...styles.bodyCell,
                ...styles.headerCell,
                ...globalStyles[key.toLowerCase()],
                alignItems: "center",
              }}
            >
              {key}
            </Text>
          ))}
        </View>
        {data.map((row, i) => (
          <View key={`row-${i}`} style={styles.tableRow} wrap={false}>
            {keys.map((key, j) => (
              <Text
                key={`cell-${j}`}
                style={{
                  ...styles.bodyCell,
                  ...globalStyles[key.toLowerCase()],
                }}
              >
                {row[key]}
              </Text>
            ))}
          </View>
        ))}
      </View>
    </>
  );
};

export const CustomerInfoPDFTable = ({ account, customer, styles }) => (
  <View style={styles.row}>
    <View style={styles.col}>
      <Image style={styles.logo} src={getCompanyLogoURI(account)} />
    </View>
    <View style={{ ...styles.col, paddingTop: 5 }}>
      <Text>{customer.address_one}</Text>
      {customer.address_two && <Text>{customer.address_two}</Text>}
      <Text>
        {customer.city} {customer.state} {customer.zip}
      </Text>
      <Text>{customer.phone}</Text>
    </View>
  </View>
);

/**
 * Google API Auth
 * ~~~~~~~~~~~~~~~~~~~~
 *
 * */

const GAuthContactsBtn = ({
  loading,
  onLoadingChange,
  onAuthStart,
  onAuthSuccess,
}) => {
  const handleAuthBtnClick = () => {
    onAuthStart("contacts", onAuthSuccess);
    onLoadingChange(true);
    setTimeout(() => onLoadingChange(false), 5000);
  };

  if (loading)
    return (
      <>
        <div
          className="spinner-border spinner-border-sm text-secondary"
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
        &nbsp;
        <CButton className="px-0" color="link" disabled={true}>
          {Strings.GOOGLE_CONTACTS_AUTH_CTA}
        </CButton>
      </>
    );

  return (
    <>
      <CIcon
        content={brandSet.cibGmail}
        className="text-danger border rounded-sm"
      />
      <CButton
        className="px-0"
        color="link"
        disabled={loading}
        onClick={handleAuthBtnClick}
      >
        &nbsp;
        {Strings.GOOGLE_CONTACTS_AUTH_CTA}
      </CButton>
    </>
  );
};

export const GAuthBtn = ({
  type,
  userPath,
  loading,
  handleLoadingChange,
  handleAuthSuccess,
}) => {
  const firebase = useContext(FirebaseContext);

  const finishGoogleAuth = (event, windowName, callback) => {
    let allowedSources = ["https://app.anansii.com", "http://localhost:3000"];
    if (!allowedSources.includes(event.origin)) return;
    //console.log(event.source.name);
    if (event.source.name !== windowName) return;

    let { data } = event;

    if (data === "success") callback();
    else console.error("something went wrong");
  };

  const startGoogleAuth = () => {
    let previousUrl = null;
    let windowObjectReference = null;
    let windowName = "gAuthWindow";

    //console.log(userPath);

    firebase
      .getGoogleAuthUri(type, userPath)
      .then(({ data }) => {
        //console.log(data);
        window.removeEventListener("message", finishGoogleAuth);

        let url = data;
        let windowFeatures =
          "toolbar=no, menubar=no, width=700, height=700, top=50, left=50";

        if (windowObjectReference === null || windowObjectReference.closed) {
          windowObjectReference = window.open(
            url,
            `${windowName}${type}`,
            windowFeatures
          );
        } else if (previousUrl !== url) {
          windowObjectReference = window.open(
            url,
            `${windowName}${type}`,
            windowFeatures
          );
          windowObjectReference.focus();
        } else {
          windowObjectReference.focus();
        }

        window.addEventListener("message", (event) =>
          finishGoogleAuth(event, `${windowName}${type}`, handleAuthSuccess)
        );
        previousUrl = url;
      })
      .catch((err) => {
        console.error("error retrieving google auth url", err);
      });
  };

  //console.log("google auth button", type, userPath);

  return (
    <>
      {
        {
          contacts: (
            <GAuthContactsBtn
              loading={loading}
              onLoadingChange={handleLoadingChange}
              onAuthStart={startGoogleAuth}
              onAuthSuccess={handleAuthSuccess}
            />
          ),
          sheets: null,
        }[type]
      }
    </>
  );
};

/**
 * Buttons
 *
 * */

export const PayButton = ({
  cta = "Pay",
  amount,
  surcharge = 0,
  isLoading,
  isDisabled = false,
  isBlockBtn = true,
  isOutlineBtn = false,
  handlePayment,
}) => {
  const classes = isBlockBtn
    ? "font-weight-bold btn-block"
    : "font-weight-bold";
  const variant = isOutlineBtn ? "outline" : "";
  const buttonTxt =
    surcharge === 0
      ? `${cta} ${formatAsMoney(amount)}`
      : `${cta} ${formatAsMoney(amount + surcharge)} (includes ${formatAsMoney(
          surcharge
        )} fee)`;

  //console.log(amount, surcharge, isLoading);

  return (
    <>
      {
        {
          false: (
            <CButton
              type="submit"
              color="primary"
              shape="pill"
              variant={variant}
              className={classes}
              disabled={isDisabled || amount === 0}
              onClick={handlePayment}
            >
              {buttonTxt}
            </CButton>
          ),
          true: (
            <CButton
              type="submit"
              color="primary"
              shape="pill"
              variant={variant}
              className={classes}
              disabled={true}
              onClick={handlePayment}
            >
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </CButton>
          ),
        }[isLoading]
      }
    </>
  );
};

export const UseCreditsCheckbox = ({
  creditsBalance,
  appliedCreditMemos,
  paymentAmount,
  useCreditsSelected,
  toggleUseCreditsSelected,
}) => {
  const [useCreditsDesc, setUseCreditsDesc] = useState(null);
  useEffect(() => {
    if (!creditsBalance) return;
    const creditsStr = creditsBalanceText(paymentAmount, creditsBalance);
    setUseCreditsDesc(creditsStr);
  }, [paymentAmount, creditsBalance]);

  const [creditMemosAppliedDesc, setCreditMemosAppliedDesc] = useState(null);
  useEffect(() => {
    if (!appliedCreditMemos) return;
    const cmstr = appliedCreditMemos.map((cm) => ` #${cm.id}`);

    const msg = `Credit memo${
      appliedCreditMemos.length > 1 ? "s" : ""
    } ${cmstr} will be used for this payment`;
    setCreditMemosAppliedDesc(msg);
  }, [appliedCreditMemos]);

  return (
    <>
      <CRow className="text-dark mx-0 font-weight-bold">
        <CCol className="px-0">
          <CFormGroup variant="checkbox" className="mx-0 py-1" row>
            <CInputCheckbox
              id="use-credits-checkbox"
              name="use-credits-checkbox"
              key="use-credits-checkbox"
              checked={useCreditsSelected}
              onChange={() => toggleUseCreditsSelected(!useCreditsSelected)}
            />
            <CLabel
              htmlFor="use-credits-checkbox"
              //className="mx-1"
              variant="checkbox"
            >
              {useCreditsDesc}
            </CLabel>
          </CFormGroup>
        </CCol>
      </CRow>
      {appliedCreditMemos && appliedCreditMemos.length > 0 && (
        <CRow className="text-dark">
          <CCol>
            <span>{creditMemosAppliedDesc}</span>
          </CCol>
        </CRow>
      )}
    </>
  );
};

export const InlineIconConfirmationButtons = ({
  label,
  size,
  confirmCallback,
  cancelCallback,
}) => {
  return (
    <>
      <CRow className="bg-light rounded-lg py-2 float-right">
        <CCol xs="auto">
          <span className="text-body font-weight-bold">{label}</span>
        </CCol>
        <CCol xs="auto" className="px-2">
          <CIcon
            title={`${label
              .slice(0, -1)
              .replace(/\s+/g, "-")
              .toLowerCase()}-confirmation-button`}
            className="text-primary"
            content={freeSet.cilCheckCircle}
            style={{ cursor: "pointer" }}
            size={size}
            onClick={confirmCallback}
          />
        </CCol>
        <CCol xs="auto" className="px-2">
          <CIcon
            title={`${label
              .slice(0, -1)
              .replace(/\s+/g, "-")
              .toLowerCase()}-cancel-button`}
            className="text-danger"
            content={freeSet.cilXCircle}
            style={{ cursor: "pointer" }}
            size={size}
            onClick={cancelCallback}
          />
        </CCol>
      </CRow>
    </>
  );
};

export const Scrim = ({ text, showSpinner = true }) => {
  return (
    <CRow className="scrim text-white text-center">
      <CCol>
        {showSpinner && (
          <CRow>
            <CCol>
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </CCol>
          </CRow>
        )}
        <CRow className="my-3">
          <CCol>
            <h5>{text}</h5>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
  );
};

export const FormInputField = ({
  name,
  label,
  value,
  error,
  maxLength = null,
  showLabel = true,
  onChange,
}) => {
  return (
    <>
      {showLabel && (
        <CLabel
          htmlFor={name}
          className="text-uppercase small font-weight-bold"
        >
          {label}
        </CLabel>
      )}
      <CInput
        id={name}
        name={name}
        className="text-body"
        value={value}
        onChange={onChange}
        invalid={!!error}
        maxLength={maxLength}
        aria-label={name}
      />
      <p className="text-danger">
        {error ? error.replace("This field", label) : null}
      </p>
    </>
  );
};

export const DropdownMenu = ({
  name,
  options,
  selectedOption,
  handleSelectionChange,
}) => {
  return (
    <CDropdown>
      <CDropdownToggle
        color="primary"
        shape="pill"
        variant="outline"
        className="px-4 font-weight-bold"
        aria-label={name}
      >
        {selectedOption}
      </CDropdownToggle>
      <CDropdownMenu>
        {options.map((option, index) => (
          <CDropdownItem
            key={index}
            onClick={() => handleSelectionChange(option)}
          >
            {option}
          </CDropdownItem>
        ))}
      </CDropdownMenu>
    </CDropdown>
  );
};

export const AccountInvoiceSelection = ({
  accountOpenInvoices,
  payerAccount,
  handleOpenAccountInvoiceSelected,
}) => {
  const [inColumns, setInColumns] = useState(null);
  useEffect(() => {
    if (!accountOpenInvoices || !payerAccount) {
      setInColumns([]);
    } else {
      const numColumns = 3;
      const copy = accountOpenInvoices.slice();
      const columns = Array.from(
        { length: Math.ceil(copy.length / numColumns) },
        (_, i) => copy.splice(0, numColumns)
      );

      const remainder =
        columns.length > 0 ? columns[columns.length - 1].length % 3 : 0;
      if (remainder !== 0) {
        let filler = null;
        for (let i = 0; i < 3 - remainder; i++) {
          columns[columns.length - 1].push(filler);
        }
      }
      setInColumns(columns);
    }
  }, [accountOpenInvoices]);

  if (!inColumns || !payerAccount) return null;

  return (
    <CRow className="mt-n3 border-bottom pb-1">
      <CCol>
        <span className="small text-muted">Open Invoices</span>
        {inColumns.map((col) => (
          <>
            <CRow>
              {col.map((inv) =>
                inv ? (
                  <CCol key={`open-invoice-col-${inv.id}`}>
                    <CButton
                      color="link"
                      key={`open-invoice-btn-select-${inv.id}`}
                      className="p-0 small border-0 align-baseline"
                      onClick={() =>
                        handleOpenAccountInvoiceSelected(inv.index)
                      }
                      disabled={inv.isSelected}
                    >
                      Inv#{inv.id} ({formatAsMoney(inv.balance / 100)})
                    </CButton>
                  </CCol>
                ) : (
                  <CCol></CCol>
                )
              )}
            </CRow>
          </>
        ))}
      </CCol>
    </CRow>
  );
};
